import React, { Component } from "react";
import "./TrainerTasks.css";


export default class TrainerTasks extends Component {
  
  

  render() {
    return (
      <div className="TrainerTasks">
         <h1>Trainer Tasks</h1>
         List of tasks
      </div>
    );
  }
}