/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import ReactTable from "react-table";
import 'react-table/react-table.css'

import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import { isNullOrUndefined } from "util";
import htmlInputs from '../../../func/htmlInputs.js'; 
import jsFunc from '../../../func/jsFunc.js'; 
import pageFrame from '../../../func/pageFrame.js'; 
//import pageFrameAsync from '../../../func/pageFrameAsync.js'; 
import { strictEqual } from "assert";
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";

const data = [ { firstName: "judge", lastName: "babies", age: 16 }, ]; const columns = [ { Header: "People list", columns: [ { Header: "First Name", accessor: "firstName" }, { Header: "Last Name", id: "lastName", accessor: d => d.lastName }, { Header: "Age", accessor: "age" } ] } ];
var InitialData={ ModelType:"Example Model", data:data, columns:columns };

export default class pageWrapper extends Component {

  constructor(props) {
    super(props);
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;
    var selroleid=urlvar["r"]?urlvar["r"]:0;

    this.state = {
      
      isLoading: true,
      frames:[
        {
          name:"mainlist",
          action:"read",
          displayFormat:"reacttable",
          tableProp:{
            filterable:true,
            searchHeaders:[],
            buttons:[{
              name:"View",
              class:"myButton myButton_blue",
              onclick: this.viewRow
            }],
          },
          buttons:[
            {
              name:"New",actions:["read"],class:"myButton myButton_blue",
              onclick: () => this.renderDetails({action:"create"}),
            }
          ],
          isDisplay:true,
          data : InitialData,
          domVariables:{}
        },
        {
          name:"recordDetail",
          action:"read",
          displayFormat:"reacttable",
          tableProp:{
            showPagination:false
          },
          buttons:[
            {name:"Back",actions:["read","create"],class:"myButton myButton_grey",
            onclick: (() => this.refreshMainList() )},
            {name:"Edit",actions:["read"],class:"myButton myButton_green",
              onclick: (() => 
              this.setState({frames:
              pageFrame.updateFrame({
                stateFrames:this.state.frames,showFrame:true,
                name:"recordDetail",action:"edit",
              })}))
            },
            {name:"Back",actions:["edit"],class:"myButton myButton_grey",
            onclick: (() => this.editBack())
            },
            {name:"Save",actions:["edit","create"],class:"myButton myButton_blue",
              onclick: (
                () => pageFrame.editrecord_exec({
                urlPost:"/webapp",
                frames:this.state.frames,
                pageType:this.state.pageType
                })
                .then(result => {
                  
                  if(this.state.pageType=="settingsPage"){ this.refreshMainList();}
                  else{this.renderDetails({id:result}); }
                })
              )
            },
          ],
          isDisplay:false,
          data : {},
          editHeaders:[],
          primaryID:"",
          primaryName:"",
          parentID:0,
          selectedID:0,
          domVariables:{},
          subFrames:[],
        },
      ],
      pageType:"CRED",
      domVariables:{},
      selected: {}, // 
      selectAll: 0, //move into Dom Variables 
      transInputModel : []
    };
		this.toggleRow = this.toggleRow.bind(this); //bind Checkbox
  }
  async componentDidMount() {
    this.refreshMainList();
    this.setState({ isLoading: false });    
  }
  refreshMainList(){ 

    this.setState({frames:
      pageFrame.updateFrame({
      stateFrames:this.state.frames,showFrame:true,
      name:"mainlist"})
    });

    this.getMainListitems()
      .then((r) => {
        var pageType=r.pageType||"CRED";
        this.state.pageType=pageType;

        //find menuitem frame
        var index=0;
        for(var i=0; i<this.state.frames.length;i++){
        if(this.state.frames[i].name=="mainlist"){index=i;}};  

        var frames=this.state.frames;
        frames[index].checkbox=r.checkbox;
        frames[index].checkAll=r.checkAll;
        frames[index].checkSelected=r.checkSelected;
        frames[index].data=r.data;
        frames[index].primaryID=r.primaryID;
        frames[index].primaryName=r.primaryName;
        frames[index].tableProp.searchHeaders=r.searchHeaders;
        frames[index].reactTableRef=React.createRef();
        
        //if pageType == toggle, change main page buttons
        if(pageType=="toggleList"){          
          frames[index].buttons=[];//remove CRED buttons
          var newButtons=[];//remove CRED row buttons
          r.editHeaders.forEach(h=>{
            h.data.forEach(d=>{
              newButtons.push({
                name:d.name,
                class:"myButton myButton_blue",
                onclick: this.toggleButton,
                data: {
                  name:d.name,
                  value:d.value,
                  inputID:h.inputID
                }
              });
            })
          });
          frames[index].tableProp.buttons=newButtons;
        }        
        if(pageType=="settingsPage"){
          frames[index].buttons=[];//remove CRED buttons
          frames[index].buttons=[
            {
              name:"Auto Create",actions:["read"],
              class:"myButton myButton_blue",
              onclick: () => pageFrame.defaultSettings_exec({
                urlPost:"/webapp",
                selectedID:"0",
                post_arr:{}}).then(result => {
                  this.editBack().bind(this);
                })
            }
          ];//Add (add ddefault) button

          //add edit button on each row
          frames[index].tableProp.buttons=[
            {
              name:"Edit",
              class:"myButton myButton_green",
              onclick: this.editRow
            }
          ];

        }

        //add any new buttons
        r.navButton.forEach(b=>{
          if(b.type=="href"){
            frames[index].buttons.push(
              {
                name:b.name,actions:["read","edit"],class:"myButton myButton_blue",
                onclick: 
                ()=>location.href="/"+b.route+"?r="+
                (((jsFunc.getUrlParameter(window.location.href)).r)||""),
              }
            );
          }else if(b.type=="post"){
            var func=b.func||"";
            frames[index].buttons.push(
              {
                name:b.name,
                actions:["read","edit"],class:"myButton myButton_blue",
                onclick: ()=>pageFrame.general_exec({
                  frame:frames[index],func
                }),
              }
            );
          }
        })




        this.setState({frames});
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async getMainListitems () {
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;
    var selroleid=urlvar["r"]?urlvar["r"]:0;
    return await API.get('v1', '/webapp?page='+route+'&roleid='+selroleid+'&'+$.param(urlvar));
  }
  //getItemDetails = async event => {
  async getItemDetails () {
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;
    var roleid=urlvar["r"]?urlvar["r"]:0;
    //get frame data
    var frame;
    this.state.frames.forEach(x => {if(x.name=="recordDetail"){frame=x;}});
    var urlpar={roleid,frame:frame.name,
    action:frame.action,selectedID:frame.selectedID};
    return await API.get('v1', '/webapp?page='+route+'&'+$.param(urlpar)+'&'+$.param(urlvar));
  }
  getTableRef(event){
    console.log("getTableRef");console.log(event);
  }
  handleDomChange(event) {    
    for(var i=0;i<this.state.frames.length;i++){
    if(this.state.frames[i].isDisplay){
      this.state.frames[i].domVariables[event.target.id]=event.target.value;
    }}
  }
  toggleCheckbox(prop){
    var frame=prop.frame;
    var id=prop.id;
    //update state - will apply to state
    var checkSelected=frame.checkSelected||[];    
    var checkSelectedNew=[];
    if(checkSelected.includes(id)){ //remove from array
      checkSelected.forEach(x=>{if(x!=id){checkSelectedNew.push(x);}})
      checkSelected=checkSelectedNew;
    }else{
      checkSelectedNew=checkSelected;
      checkSelectedNew.push(id);
    }
    return checkSelectedNew;
  }
  checkAll(prop){
    var frame=prop.frame;
    //handle check all
    if(frame.checkAll){ //untick all
      frame.checkAll=false;
      frame.checkSelected=[];
    }else{ //tick all
      frame.checkAll=true;
      var checkSelectedNew=[];
      
      //tick only visible lines
      frame.reactTableRef.current.getResolvedState().sortedData.forEach(s=>{
        checkSelectedNew.push(s[frame.primaryID]);
      });
      //frame.data.data.forEach(x=>{checkSelectedNew.push(x[frame.primaryID]);});

        frame.checkSelected=checkSelectedNew;
    }
    this.sumHeaders({frame});
  }
  sumHeaders(prop){
    var frame=prop.frame;
    //find columns to sum
    var sumHeaders={};
    frame.tableProp.searchHeaders.forEach(s=>{
      if(s.datatype=="decimal"){
        sumHeaders[s.inputID]={total:0,inputName:s.inputName};}})

    //add totals for all decimal columns
    frame.data.data.forEach(d=>{
      if(frame.checkSelected.includes(d[frame.primaryID])){
        for (var key in sumHeaders){
          sumHeaders[key].total+=parseFloat(d[key]);}
      }
    });

    console.log(sumHeaders);
    frame.reactTableRef.current.getResolvedState().sortedData.forEach(s=>{
      s[frame.primaryID]

    })

    frame.data.columns.columns.forEach(c=>{
      if(c.accessor in sumHeaders){
        var dtotal=(sumHeaders[c.accessor].total)
        ?" ("+(sumHeaders[c.accessor].total).toFixed(2)+")":"";
        c.Header=sumHeaders[c.accessor].inputName+dtotal;
      }
    })
  }
  toggleRow = ev => {
    var prop=JSON.parse(ev.currentTarget.value);
    var id=prop.id;
    var frameName=prop.frameName;  
    //find frame
    this.state.frames.forEach(frame=>{

      //check main frame for TB
      if(frame.name==frameName){
        frame.checkSelected=this.toggleCheckbox({frame,id});
        console.log("checkSelected");console.log(frame.checkSelected);
        this.sumHeaders({frame});
      }  
      //check subframes for TB name
      (frame.subFrames||[]).forEach(subFrame=>{
        if(subFrame.name==frameName){
          subFrame.checkSelected=this.toggleCheckbox({frame:subFrame,id});}
          this.sumHeaders({frame});
      });
    });
    this.forceUpdate();
    /*
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[id] = !this.state.selected[id];
		this.setState({
			selected: newSelected,
			selectAll: 2
    });
    */

  }  
	toggleCheckAll = ev => {
    var prop=JSON.parse(ev.currentTarget.value);
    var frameName=prop.frameName;  
    //find frame
    this.state.frames.forEach(frame=>{
      if(frame.name==frameName){
        this.checkAll({frame});
      }      
      (frame.subFrames||[]).forEach(subFrame=>{
        if(subFrame.name==frameName){          
          this.checkAll({frame:subFrame});   
        }
      });
    });
    this.forceUpdate();
  }
  editBack(){
    if(this.state.pageType=="settingsPage"){ //go back to main page      
    this.setState({frames:
      pageFrame.updateFrame({              
      stateFrames:this.state.frames,showFrame:true,
      name:"mainlist",action:"read",
      })});
    }else{
    this.setState({frames:
      pageFrame.updateFrame({              
      stateFrames:this.state.frames,showFrame:true,
      name:"recordDetail",action:"read",
      })});
    }
  }
  editRow = ev => {    
    var buttonVal=JSON.parse(ev.currentTarget.value);
    var tableData=buttonVal.tableData;
    
    //find primary ID col
    var primaryID;
    this.state.frames.forEach(frame=>{
      if(frame.name=="mainlist"){
        primaryID=frame.primaryID; }          
    });
    var id=tableData[primaryID];
    this.renderDetails({id,action:"edit"});
  }
  viewRow = ev => {    
    var buttonVal=JSON.parse(ev.currentTarget.value);
    var tableData=buttonVal.tableData;
    
    //find primary ID col
    var primaryID;
    this.state.frames.forEach(frame=>{
      if(frame.name=="mainlist"){primaryID=frame.primaryID;}});
    var id=tableData[primaryID];
    this.renderDetails({id});
  }
  toggleButton = ev => {    
    var buttonVal=JSON.parse(ev.currentTarget.value);
    var tableData=buttonVal.tableData;
    var data=buttonVal.data;
    ev.currentTarget.textContent="..";
    //find primary ID col
    var primaryID, index;
    for(var i=0; i<this.state.frames.length;i++){
      var frame=this.state.frames[i];
      if(frame.name=="mainlist"){
        primaryID=frame.primaryID;
        index=i;
      }
    };
    var id=tableData[primaryID];
    this.state.frames[index].selectedID=id;
    this.state.frames[index].action="edit";
    //send toggleEXEC
    //console.log(id); console.log(data);    
    var post_arr={};
    post_arr[data.inputID]=data.value;

    pageFrame.toggleRecord_exec({
      urlPost:"/webapp",
      selectedID:id,
      post_arr
    }).then(result => {
      this.refreshMainList();
      
    })
    
    ;

    //this.renderDetails({id});
  }
  renderDetails(prop){
    var id=prop.id?prop.id:0;
    var action=prop.action?prop.action:"read";
    //change frame
    this.setState({frames:
      pageFrame.updateFrame({
      name:"recordDetail",action,
      showFrame:true,selectedID:id,
      stateFrames:this.state.frames
    })});
    //clear frame data
    var f=pageFrame.clearFrameData({
    name:"recordDetail",stateFrames:this.state.frames});
    this.setState({frames:f.frames});
    
    this.getItemDetails().then((r) => {
      console.log("getItemdetails");
      console.log(r);
      var frames=this.state.frames;
      frames[f.index].data=r.data;      
      frames[f.index].primaryID=r.primaryID;
      frames[f.index].editHeaders=r.editHeaders;
      frames[f.index].subFrames=r.subFrames||[];
      this.setState({frames});
    }).catch((err) => {console.log(err);});
  }
  
  render() {
    var stateFrames=this.state.frames;
    var frames=[];
    stateFrames.forEach(frame=>{
      frames.push(pageFrame.render({
        pageType:this.state.pageType,
        //stateFrames,
        frame,
        handleDomChange:this.handleDomChange.bind(this),
        toggleRow:this.toggleRow.bind(this),
        toggleCheckAll:this.toggleCheckAll.bind(this)
      }))
    });
    
    stateFrames.forEach(frame=>{
      //clean out Dom Variable data
      if(frame.action=="create" || frame.action=="edit"){
        frame.domVariables={};}
      //add primary ID to domVar      
      if(frame.action=="edit"){
        frame.domVariables[frame.primaryID]=frame.selectedID;
      }      
      //handle load default values on initial load
      if(frame.action=="create"){
      var editHeaders=frame.editHeaders||[];
      editHeaders.forEach(editHeader=>{
      if(editHeader.defaultVal || !frame.domVariables[editHeader.inputID]){
        frame.domVariables[editHeader.inputID]=editHeader.defaultVal;
      }
      });}
      //copy checklist Data
      //(frame.subFrames||[]).forEach(subFrame=>{if(subFrame.type=="checklist"){ 
      //subFrame.checkSelected=subFrame.dataChecked;}});
  
    });

    if (this.state.isLoading===true){
      return (
        <div className="table-wrapper">
          Please wait as we retrieve your records...
        </div>

      );
    }else{

      return (<div>{frames}</div>);

    }
  } 
}