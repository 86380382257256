/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import "./CustomerReach.css";
import { API } from "aws-amplify";
import { isNullOrUndefined } from "util";
import jsFunc from '../../../func/jsFunc.js'; 
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";


const UserAddressModel = {
  cognito_id : "testcognito",
  saved_address : [
    {
      "add_id":1,
      "address_type":"work",
      "str_no":"185",
      "str_name":"Fanshawe123",
      "str_type":"Street",
      "suburb":"Auckland CBD",
      "city":"Auckland",
      "postcode":"1010",
      "country":"New Zealand",
      "is_default":"1"
    },
    {
      "add_id":2,
      "address_type":"home",
      "str_no":"157",
      "str_name":"Tiriwa123",
      "str_type":"Drive",
      "suburb":"Massey",
      "city":"Auckland",
      "postcode":"0614",
      "country":"New Zealand",
      "is_default":"1"
    }
  ]
}
const inputModel=[
  {"field_name":"Trading Name:","field_id":"business_name"},
  {"field_name":"Building/Site Name:","field_id":"site_name"},
  {"field_name":"Street Number:","field_id":"str_no"},
  {"field_name":"Street Name:","field_id":"str_name"},
  {"field_name":"Suburb:","field_id":"suburb"},
  {"field_name":"Postcode:","field_id":"postcode"},
  {"field_name":"City:","field_id":"city"},
  {"field_name":"Country","field_id":"country"},
  {"field_name":"Address Type","field_id":"address_type"},
  {"field_name":"Active?","field_id":"is_default"},
  {"field_name":"Customer Activity:","field_id":"at_id_reach"}
];
const addtype_desc={
  1:"Home",
  2:"Work",
  3:"Study",
  4:"Your Business Locations",
  5:"Customer Reach",
  6:"Commute",
  7:"Leisure"
};

export default class UserAddress extends Component {

  constructor(props) {
    super(props);
    for (var i=0; i<inputModel.length; i++){
    this[inputModel[i]["field_id"]] = React.createRef();
    }
    this["str_name"]=React.createRef();

    var selroleid=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}

    this.state = {
      isLoading: true,
      add_id_sel: -1,
      selectedRoleId : selroleid,
      new_add_type: "",
      UserAddress : UserAddressModel
    };
  }
  async componentDidMount() {
    this.getUserAddress()
      .then((data) => {
        //this.state.UserAddress=data;
        this.setState({
          UserAddress : data
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ isLoading: false });    
  }  
  getUserAddress = async event => {
    //return await API.get('dev-profile-service', '/useraddress');
    
    var selroleid=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}

    return await API.get('v1', '/useraddress?roleid='+selroleid);
  }
  edit_address_exe = async event => {

    /*
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {var str_name = node.querySelector('#str_name');}
    alert(str_name.value);
    */
    //alert(this.str_name.current.value);
    var post_arr={}; var field_value; var field_id;
    post_arr["add_id"]=this.state.add_id_sel;
    //post_arr["add_type"]=this.state.new_add_type;
    for (var i=0; i<inputModel.length; i++){
      if(this[inputModel[i]["field_id"]].current!==null 
      && this[inputModel[i]["field_id"]].current!==undefined){
      field_value=this[inputModel[i]["field_id"]].current.value;
      field_id=inputModel[i]["field_id"];
      post_arr[field_id]=field_value;
      }
    }
    post_arr["rl_id"]=this.state.selectedRoleId;
    

    return await API.post('v1', '/useraddress',
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      console.log(JSON.stringify(result));
      //alert(JSON.stringify(result));
      //retreive user address again
      this.getUserAddress()
      .then((data) => {
        this.setState({
          UserAddress : data
        });
      })
      .catch((err) => {
        console.log(err);
      });

      this.state.add_id_sel=-1;
      this.state.new_add_type="";
      //this.forceUpdate();
    });
    
  }
  renderCognito(){
    return (this.state.UserAddress.cognito_id);
  }

  new_address(add_type){
    this.state.new_add_type=add_type;
    this.state.add_id_sel=0;
    this.forceUpdate();
  }
  edit_address(add_id){
    this.state.add_id_sel=add_id;
    this.forceUpdate();
  }
  edit_address_clear(){
    this.state.add_id_sel=-1;
    this.state.new_add_type="";
    this.forceUpdate();
  }

  renderAddress(add_type){
    var UserAddress=this.state.UserAddress.saved_address;
    let home_add_arr=[];let home_add_sel=[];
    var nrow=UserAddress.length; 
    if(this.state.add_id_sel===0 && add_type===this.state.new_add_type){nrow=1;}
    var addarr; var full_add;

    for (var i=0;i<nrow;i++){
      if (add_type===this.state.new_add_type){
        addarr=this.state.UserAddress.new_address;
        addarr["address_type"]["input_value"]=add_type;
      }else {addarr=UserAddress[i];}

      if(addarr["address_type"]["input_value"]===add_type){
        full_add="";
        if (add_type==4){ //site address
        full_add+=addarr["business_name"]["input_value"];  full_add+=", "+addarr["site_name"]["input_value"]+", ";}
        full_add+=addarr["str_no"]["input_value"];  full_add+=" "+addarr["str_name"]["input_value"];
        //full_add+=" "+addarr["str_type"];
        full_add+=", "+addarr["suburb"]["input_value"]; full_add+=", "+addarr["city"]["input_value"]; 
        full_add+=" "+addarr["postcode"]["input_value"]; full_add+=", "+addarr["country"]["input_value"];
        if (add_type==5){//customer reach
        full_add+=" ("+addtype_desc[addarr["at_id_reach"]["input_value"]]+")";}

        const add_id=addarr["add_id"]["input_value"];
        var edit_button=(<span class="myButton_edit myButton" onClick={() => this.edit_address(add_id)} >Edit</span>);
        var default_ind=(<span className="default_set">(Set as default)</span>); 
        default_ind=""; //set via edit

        if(addarr["is_default"]["input_value"]===1){
          default_ind=(<span className="default_add">Active</span>);
        }

        if (add_id===this.state.add_id_sel){ //edit/new
          var edit_but=(<span class="myButton_edit myButton" onClick={() => this.edit_address_exe()}>Save</span>);
          var cancel_but=(<span class="myButton_cancel myButton" onClick={() => this.edit_address_clear()}>Cancel</span>);
          
          var dfield_arr=[];
          var inputbox;
          for (var i=0; i<inputModel.length;i++){
            if (add_type==4){var col_exclude=["at_id_reach"];} //supplier site
            if (add_type==5){var col_exclude=["business_name","site_name"];} //customer reach
            
            if(!col_exclude.includes(inputModel[i]["field_id"])){
              var input_type="text";
              if(addarr[inputModel[i]["field_id"]]["input_prop"]!==undefined){
                input_type=addarr[inputModel[i]["field_id"]]["input_prop"]["type"];
              }

              if(input_type==="text" || input_type==="hidden"){
                inputbox=(
                  <input 
                  type={inputModel[i]["field_type"]} 
                  id={inputModel[i]["field_id"]} 
                  ref={this[inputModel[i]["field_id"]]}
                  className="input_tb" 
                  defaultValue={addarr[inputModel[i]["field_id"]]["input_value"]} />
                );
              }else if(input_type==="dropdown"){
                var dd_selection=[];
                var dd_arr=addarr[inputModel[i]["field_id"]]["input_prop"]["selection"];
                for(var i_dd=0; i_dd<dd_arr.length; i_dd++){
                  dd_selection.push(
                    <option value={dd_arr[i_dd]["value"]}>{dd_arr[i_dd]["display"]}</option>
                  );
                }
                inputbox=(
                  <FormGroup>
                    <FormControl componentClass="select"
                    className="input_tb" 
                    id={inputModel[i]["field_id"]} 
                    inputRef={this[inputModel[i]["field_id"]]}
                    defaultValue={addarr[inputModel[i]["field_id"]]["input_value"]}>
                    {dd_selection}
                    </FormControl>
                  </FormGroup>
                );
              }

              var div_class="";if(input_type==="hidden"){div_class="hidden";}
              dfield_arr.push(
                <div className={div_class}>
                  <div className="div_fieldname">{inputModel[i]["field_name"]}</div>
                  <div className="div_fieldinput">{inputbox}</div>
                </div>
              );
            }
          }
          home_add_sel.push(<div className="div_lineoption">{dfield_arr}<div>{edit_but} {cancel_but}</div></div>);
        }else{//view
          home_add_arr.push(<div className="div_lineoption">{full_add} {edit_button} {default_ind}</div>);
        }
      }
    }
    var add_new=(<button className="myButton_new myButton" onClick={() => this.new_address(add_type)}>Add</button>);
    var dadd_type=addtype_desc[add_type]; 
    //if (add_type==4){dadd_type="Your Business Locations";}
    //if (add_type==5){dadd_type="Customer Reach";}

    if(add_type===this.state.new_add_type){add_new="";}
    if (home_add_sel.length>0){home_add_arr=home_add_sel;add_new="";}
    return(<div>
      <div class="section_header">{dadd_type}</div>
      {home_add_arr} {add_new}
      </div>);
  }

  render() {
    return (
      <div>
        <div>
          Entering your addresses below helps Tom Push your Menu items to your Customers located in your area.
          It is good to ensure that you are able to service the customer in the areas you have listed.
        </div>
        <div>{this.renderAddress(4)}</div>
        <div>{this.renderAddress(5)}</div>
      </div>
    );
  }
}