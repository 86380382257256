import { Auth } from "aws-amplify";

const dev = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    API: {
      endpoints: [
        {
          name: "v1",
          endpoint: "https://o2ev3ql1hd.execute-api.us-east-1.amazonaws.com/dev",
          region: "us-east-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
          }
        },
        {
          name: "profile-service",
          endpoint: "https://w0oazll0pc.execute-api.us-east-1.amazonaws.com/dev",
          region: "us-east-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
          }
        }
      ],
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_21bxa0ISX",
      //APP_CLIENT_ID: "6igeauvo3n8tnbtups3kvaqg6o",
      APP_CLIENT_ID: "6osqfv5b79epg4npm71spj0h20", // no app secret
      IDENTITY_POOL_ID: "us-east-1:c50e2a20-2211-4d75-950a-5d1f67bb5d36"
    },
    oauth: {
      domain : 'auth.robot-hub.com', 
  
      // Authorized scopes
      scope : ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'], 
      redirectSignIn : 'http://localhost:3000/', 
      redirectSignOut : 'http://localhost:3000/',
      //responseType: 'token',
      responseType: 'code', //refresh token
  
      // optional, for Cognito hosted ui specified options
      options: {
          AdvancedSecurityDataCollectionFlag : true
      }
    }
  };

const test = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    API: {
      endpoints: [
        {
          name: "v1",
          endpoint: "https://o2ev3ql1hd.execute-api.us-east-1.amazonaws.com/dev",
          region: "us-east-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
          }
        },
        {
          name: "profile-service",
          endpoint: "https://w0oazll0pc.execute-api.us-east-1.amazonaws.com/dev",
          region: "us-east-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
          }
        }
      ],
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_21bxa0ISX",
      //APP_CLIENT_ID: "4f1eofo7tqsf17bm57gunghkqv",
      APP_CLIENT_ID: "1jqc0cte1dfts0pmjnmdrmpn92", //no app scret
      IDENTITY_POOL_ID: "us-east-1:c50e2a20-2211-4d75-950a-5d1f67bb5d36"
    },
    oauth: {
      domain : 'auth.robot-hub.com', 
  
      // Authorized scopes
      scope : ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'], 
      redirectSignIn : 'https://test.robot-hub.com/', 
      redirectSignOut : 'https://test.robot-hub.com/',
      //responseType: 'token',
      responseType: 'code', //refresh token
      // optional, for Cognito hosted ui specified options
      options: {
          AdvancedSecurityDataCollectionFlag : true
      }
    }
  };

  const prod = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    API: {
      endpoints: [
        {
          name: "v1",
          endpoint: "https://o2ev3ql1hd.execute-api.us-east-1.amazonaws.com/dev",
          region: "us-east-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
          }
        },
        {
          name: "profile-service",
          endpoint: "https://w0oazll0pc.execute-api.us-east-1.amazonaws.com/dev",
          region: "us-east-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
          }
        }
      ],
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_21bxa0ISX",
      //APP_CLIENT_ID: "ep3tucn1tqcai6qui30ei8ibh",
      APP_CLIENT_ID: "38chcvb01uijl8ov7189au04us",//no app sectret
      IDENTITY_POOL_ID: "us-east-1:c50e2a20-2211-4d75-950a-5d1f67bb5d36"
    },
    oauth: {
      domain : 'auth.robot-hub.com', 
  
      // Authorized scopes
      scope : ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'], 
      redirectSignIn : 'https://app.robot-hub.com/', 
      redirectSignOut : 'https://app.robot-hub.com/',
      //responseType: 'token',
      responseType: 'code', //refresh token
      // optional, for Cognito hosted ui specified options
      options: {
          AdvancedSecurityDataCollectionFlag : true
      }
    }
  };

const buildEnvironment = process.env.REACT_APP_STAGE || "dev";
const buildVersion = process.env.BUILD_VERSION || "0.0.0.0";
const buildLabel = buildEnvironment + ' ' + buildVersion;
  // Default to dev if not set
const awsConfig = buildEnvironment === 'prod' ? prod
  : buildEnvironment === 'test' ? test
  : dev;



export default {
  environment : buildEnvironment,
  version : buildLabel,
  awsConfig
}