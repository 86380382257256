import React, { Component } from "react";
import "./Home.css";
export default class Home extends Component {


  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>Tom the Robot</h1>
          <p>Automating your tasks</p>
          Please wait as we sign you in ...
        </div>
      </div>
    );
  }
}