/* eslint-disable */
import React, { Component } from "react";
//import "./SupplierKiosks.css";
import { API, Auth } from "aws-amplify";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import jsFunc from '../../../../func/jsFunc.js'; 

const KiosksModel = 
{
  ModelType:"Preload Model",
  lastrecord_dt:0,
  Kiosks:[]
}


export default class Kiosks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lastRefresh: 0,
      connectState: "Loading",
      isLoading: true,
      data : KiosksModel
    };    
  } 


  async componentDidMount() {

    //initial load
    this.getKioskStatus()
      .then((data) => {
        console.log(data);
        //set all progress orders to show
        this.setState({
            data: data
        });
        this.forceUpdate();
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ isLoading: false });

    //refresh every 4 seconds
    try {
      setInterval(async () => {
        this.getKioskStatus()
        .then((data) => {
          var lastRefresh = Date.now();
          if(data.r==1){ this.setState({lastRefresh,data});
          }else if(data.r==2) {this.setState({lastRefresh});}
        })
        .catch((err) => {console.log(err);});
        this.setState({ isLoading: false });
      }, 4000);      
    } catch(e) {
      console.log(e);
    }
    //refresh every second
    try {
      setInterval(async () => {
        var ts = Date.now();var connectState;
        var lastRefresh=this.state.lastRefresh;
        if (lastRefresh>0){
          const secs_late=Math.floor((ts-this.state.lastRefresh)/1000); 
          if(secs_late<8){connectState="Online ("+secs_late+")";}
          else if(secs_late<15){connectState="Reconnecting.. "+secs_late;}
          else {connectState="Connection Lost. Please refresh Page. "+secs_late;}
          this.setState({connectState});
        }
      }, 1000);      
    } catch(e) {
      console.log(e);
    }

  }
  getKioskStatus = async event => {
    
    var selroleid=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}
    var lastrecord_dt=this.state.data.lastrecord_dt; 
    return await API.get('v1', '/supplierkiosks?roleid='+selroleid+'&lastrecord_dt='+lastrecord_dt);
  }
  
  async sendnotice_exec(prop) {
    var action=prop.action;
    var orderID=prop.orderID

    var selroleid=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}

    var post_arr={
      action:action,
      orderID:orderID,
      roleid:selroleid
    };
    //move Order further down list
    return await API.post('v1', '/supplierKiosks',
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      console.log(JSON.stringify(result));
      
      this.componentDidMount();
      
    });


  }
  renderKiosk(prop){
    var Kiosk=prop.Kiosk;
    var dsiteTimes="TBC";
    var status="";
    var notify="";

    return (
        <div style={{"padding-top":"20px"}}>            
            <div>Name: {Kiosk.name}</div>
            <div>lastActive: {Kiosk.lastActive}</div>
            <div>lastOrder: {Kiosk.lastOrder}</div>
            <div>Opening Hours: {dsiteTimes}</div>
            <div>Current Status: {status}</div>
            <div>Notify: {notify}</div>

        </div>

    );
  }

  render() {
    var dconnectState=(<div>{this.state.connectState}</div>);

    var showKiosks=[];
    var Kiosks=this.state.data.Kiosks;
    for (let i = 0; i < Kiosks.length; i++){
        var Kiosk=Kiosks[i];
        showKiosks.push(this.renderKiosk({Kiosk}));
    }

    return (
      <div className="div_customerorders"> 
        {dconnectState}
        <p>Hello here are your Kiosks</p>
        <p>Last Data Change: {this.state.data.lastrecord_dt}</p>

        {showKiosks}

      </div>
    );
  }
}