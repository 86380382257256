/* eslint-disable */
import React, { Component } from "react";
import Sound from 'react-sound';
import soundfile from "../../../sound/quite-impressed.mp3"

import "./SupplierIncoming.css";
import { API, Auth } from "aws-amplify";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MenuOrders from '../../objects/orders/MenuOrders.js'; 
//import * as MenuOrders from '../orders/Orders.js'; 
//import MenuOrders from '../orders/Orders.js'; 


const CustomerOrdersModel = 
{
  "ModelType":"Preloaded Model",
  lastrecord_dt:0,
  "Orders":[]
}


export default class Incoming extends Component {



  damount(x) {
    x=x.toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      lastRefresh: 0,
      intervalCounter: 0,
      sound:{notice:{status:"STOPPED",isPlaying:false}},
      connectState: "Loading",
      orderIDExpanded : [],
      CustomerOrders : CustomerOrdersModel
    };    
  }
  

  getUrlParameter(url) {
    var var_obj={};
    if(url.indexOf("?")>-1){
      var url_arr=url.split("?"); var var_arr=url_arr[1].split("&");
      var obj_single={};
      for (var i=0;i<var_arr.length;i++){
        obj_single=var_arr[i].split("="); var_obj[obj_single[0]]=obj_single[1];
      }
    }
    return(var_obj);
  }

  async componentDidMount() {

    //initial load
    this.getSupplierOrders()
      .then((data) => {
        //set all progress orders to show
        this.setState({
          CustomerOrders: data
        });
        this.ExpandOption({
          action:"add",
          ExpandCategory:"Incoming Orders",
          orders: data.Orders
        });
        this.ExpandOption({
          action:"add",
          ExpandCategory:"In Progress",
          orders: data.Orders
        });
        this.ExpandOption({
          action:"add",
          ExpandCategory:"Completed",
          orders: data.Orders
        });
        this.forceUpdate();
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ isLoading: false });

    //refresh every 8 seconds
    const int_refresh=8;

    //refresh every second
    try {
      setInterval(async () => {
        var intervalCounter= this.state.intervalCounter==(int_refresh) 
        ? 1 : this.state.intervalCounter+1;
        var refreshstate={};

        //every 8 seconds
        if(intervalCounter==1){
          //refresh token
          var currentSession=await Auth.currentSession(); 
          var currentAuthenticatedUser=await Auth.currentAuthenticatedUser();
          console.log((currentAuthenticatedUser.getSignInUserSession()).refreshToken);

          this.getSupplierOrders()
          .then((data) => {
            //update ping time       
            var lastRefresh = Date.now();
            if(data.r==1){  
              //refreshstate["lastRefresh"]=lastRefresh;
              //refreshstate["CustomerOrders"]=data;
              
              this.state.lastRefresh=lastRefresh;
              this.state.CustomerOrders=data;
              //this.setState({
              //  lastRefresh,
              //  CustomerOrders:data,
              //});
            }else if(data.r==2) {
              this.state.lastRefresh=lastRefresh;
              //refreshstate["lastRefresh"]=lastRefresh;
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }

        //sound - every 4 seconds
        if(intervalCounter==1 || intervalCounter==5){
          //check if there are current Incoming orders        
          this.state.CustomerOrders.Orders.forEach(Order => {
            if(Order.order_stage=="Incoming Orders"){
              refreshstate["sound"]={notice:{status:"PLAYING"}};
            }
          });
        }

        //every second
        var ts = Date.now();
        var connectState;
        if (this.state.lastRefresh>0){
          const secs_late=Math.floor((ts-this.state.lastRefresh)/1000); 
          if(secs_late<8){
            connectState="(Refreshed "+secs_late+" seconds ago)";
          }
          else if(secs_late<15){connectState="Reconnecting.. Inactive for "+secs_late+" seconds";}
          else {connectState="Connection Lost. Please refresh Page. Inactive for "+secs_late+" seconds";}
          
        }
        refreshstate["connectState"]=connectState;
        refreshstate["intervalCounter"]=intervalCounter;
        console.log(refreshstate);
        this.setState(refreshstate);

      }, 1000);      
    } catch(e) {
      console.log(e);
    }
    

  }
  
  playingNotice = ev => {
  //finishedNotice(){
    console.log("onplaying ");
    //this.setState({
    //  sound:{notice:{isPlaying:true}}
    //});
    this.state.sound.notice.isPlaying=true;
    //this.forceUpdate();
    
  }
  finishedNotice = ev => {
  //finishedNotice(){
    //console.log("stop sound");
    //this.setState({
    //  sound:{notice:{status:"STOPPED",isPlaying:false}}
    //});
    this.state.sound.notice.status="STOPPED";
    //this.forceUpdate();
    
  }

  getSupplierOrders = async event => {
    
    var selroleid=0;
    var urlvar=this.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}
    var lastrecord_dt=this.state.CustomerOrders.lastrecord_dt||""; 
    return await API.get('v1', '/supplierincoming?roleid='+selroleid+'&lastrecord_dt='+lastrecord_dt);
  }
  
  async order_exec(prop) {
    var action=prop.action;
    var orderID=prop.orderID

    var selroleid=0;
    var urlvar=this.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}

    var post_arr={
      action:action,
      orderID:orderID,
      roleid:selroleid
    };

    //move Order further down list before return call
    var newOrders=[];
    this.state.CustomerOrders.Orders.forEach(order => {
      if(order.OrderID==orderID){

        if(order.order_stage=="Incoming Orders"){
          order.order_stage="In Progress";
        }else if(order.order_stage=="In Progress"){order.order_stage="Completed";}
      }
      newOrders.push(order);
    });
    console.log("newOrders");
    console.log(newOrders);
    this.setState({
      CustomerOrders:{Orders:newOrders}
    });    
    return await API.post('v1', '/supplierincoming',
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      console.log(JSON.stringify(result));
      this.componentDidMount();      
    });   
  }


  renderOrderList(prop){
    var status=prop.status;
    var orderIDExpanded=this.state.orderIDExpanded;
    let Orders=this.state.CustomerOrders.Orders||[];
    var order_list=[];
    for (var i=0;i<Orders.length;i++){
      //var orderStatus=this.checkOrderStatus({"order":Orders[i]});
      if(Orders[i].order_stage==status){

      
      order_list.push(this.renderOrder({
        "order":Orders[i],
        "status":status,
        orderIDExpanded:orderIDExpanded
      }));

      };
    }
    if(order_list.length>0){

      var className=" td_header orderlist_header ";
      
      if(status=="Incoming Orders"){
        //this.setState({
        //          sound:{notice:{status:"PLAYING"}}
        //});
        className+=" orderlist_header_green ";
      }
      if(status=="In Progress"){
        className+=" orderlist_header_red ";
      }
      if(status=="Completed"){
        className+=" orderlist_header_border ";
      }

      return (
      <div>
        <div className={className} >{status}</div>
        {order_list}
      </div>
      );
    }

  }
  ExpandOption(prop){
    var action=prop.action;
    var orderID=prop.orderID; 
    var ExpandCategory=prop.ExpandCategory;
    var orders=prop.orders;
    var orderIDExpanded=this.state.orderIDExpanded;
    var orderIDExpanded_new=[];
    if(action=="add"){
      orderIDExpanded_new=orderIDExpanded;
      if(ExpandCategory!=undefined){
        for(var i=0;i<orders.length;i++){
          
          if(orders[i].order_stage==ExpandCategory){
            if(orderIDExpanded_new.indexOf(orders[i].OrderID)==-1){
            orderIDExpanded_new.push(orders[i].OrderID);}
          };
        }
      }else{
        orderIDExpanded_new.push(orderID);
      }
    }else if(action=="remove"){//remove
      for(var i=0;i<orderIDExpanded.length;i++){
        if(orderID!=orderIDExpanded[i]){
        orderIDExpanded_new.push(orderIDExpanded[i]);}
      }
    }
    this.setState({
      orderIDExpanded: orderIDExpanded_new
    });
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  renderOrder(prop){
    var order=prop.order;
    var orderIDExpanded=prop.orderIDExpanded;
    var orderID=order.OrderID;
    var Supplier=order.Supplier+" ";
    var Orderer=order.Name+" ";
    //var totalprice=this.total_orderprice({"order":order});
    var totalprice=MenuOrders.total_orderprice({"order":order});
    
    var dtotal="";if (totalprice>0){dtotal=" $"+this.damount(totalprice)+" ";}
    var dtime=""; dtime=order.TimeOrdered;
    var paymentStatus=order.payment_status.ConfirmType;
    var paymentStage=order.payment_status.stage_name;
    var paymentStatus_class="span-sub-complete";
    if(paymentStage=="Pending"){
      paymentStatus_class="span-sub-awaiting";
    }

    var OrderItems=[]; var status;
    var show_all=1;
    if(orderIDExpanded.indexOf(orderID) != -1 || show_all==1){ //only show breakdown if expanded 
    for (var i=0;i<order.orderItems.length;i++){
      var OrderItem=order.orderItems[i];
      OrderItems.push(this.renderOrderItem({OrderItem:OrderItem}));
      status="";
    }}

    var onclick_func; var dexpand=" +";
    if(orderIDExpanded.indexOf(orderID) != -1){ //if expanded, remove from array. 
      dexpand=" -";
      onclick_func=(() => this.ExpandOption({action:"remove",orderID:orderID}));
    }else{
      onclick_func=(() => this.ExpandOption({action:"add",orderID:orderID}));
    }
    dexpand="";

    var order_buttons=[
      {
        "action":"accept"
      }
    ];
    var order_buttons=order.order_buttons;

    var buttons=[];
    for (var i=0;i<order_buttons.length;i++){
      var action=order_buttons[i]["action"];
      var daction=this.capitalizeFirstLetter(action);
      buttons.push((<button onClick={
        () => this.order_exec({action:action,orderID:orderID})
      } className='myButton_new td_floatright'>{daction}</button>));
    }
    var dpaymentStatus=(<div><span className={paymentStatus_class}>{paymentStatus}</span></div>);
    return (
      <div className="td_MenuOrder_wrap">
        <div className="td_header_MenuOrder">
          <span className="span-button" onClick={onclick_func}>
            {Orderer}
            <span className='span-sub-black'>
            (#{order.OrderNumber})</span> 
            <span className="span-sub-black">{dexpand}</span>
          </span>
          {status}
          <div className="td_floatright">{buttons}</div>
          <div className="td_floatright">{dtotal}{dpaymentStatus}</div>
          <div><span className='span-sub-black'>{dtime}</span></div>
        </div>
        {OrderItems}
      </div>
    );
  }  
  renderOrderItem(prop){ //order frame - shows seleted objs
    var OrderItem=prop.OrderItem;
    var item_num=OrderItem.item_num;
    var dcurnum; dcurnum=" (#"+item_num+")"; //dcurnum=""; //item number
    var itemStatus=OrderItem.item_status.ConfirmType;
    
    //item_num_sel to put in state
    var item_num_sel=0;

    //always show summary
    //get total item price
    var totalprice=MenuOrders.total_OrderItem_price({OrderItem:OrderItem});
    var dtotalprice="$"+this.damount(totalprice);
    var addon_summary=MenuOrders.get_menuitem_summary({OrderItem:OrderItem});

    var div_class="td_tablet_hide";if(item_num_sel==item_num 
      || item_num_sel==null
      || item_num_sel==0
      ){div_class="";} div_class="";
    
    var onclick_func=(() => this.sel_order(item_num));
    var onclick_func_del=(() => this.sel_order_del(item_num));
    
    var class_sel="td_action_supplier"; 
    var edit_but=(<button onClick={onclick_func} className='myButton_edit td_floatright'>Edit</button>);
    var del_but=(<button onClick={onclick_func_del} className='myButton_del td_floatright but_margin'>X</button>);
    if(item_num_sel==item_num){
      edit_but=(<button onClick={onclick_func} className='myButton_new td_floatright'>Done</button>);
      del_but=(<button onClick={onclick_func_del} className='myButton_del td_floatright but_margin'>X</button>);
      //del_but="";
      //class_sel+=" td_action_selected";
    }
    
    return (
    <div className={div_class}>
        <div className={class_sel}>
          <div>
            {OrderItem.item_name} 
            <span className='span-order'>{dcurnum}</span>
            <span className='span-sub td_floatright'>{dtotalprice}</span>
            
          </div>
          <span className='span-sub'>{addon_summary}</span>
        </div>
    </div>
    );
  }

  render() {
    var dconnectState=(<div className="td_floatright"><b>{this.state.connectState}</b></div>);
    
    //check for sound playing status
    var notificationsound=(
        <Sound
        url={soundfile}
        playStatus={
          !this.state.sound.notice.isPlaying 
          ? this.state.sound.notice.status
          : "STOPPED"}
        playFromPosition={300 /* in milliseconds */}
        onPlaying={this.playingNotice}
        onFinishedPlaying={this.finishedNotice}
        />
      );
    

    return (
      <div className="div_customerorders"> 
          {notificationsound}
          <button className="myButton_new"
          onClick={
            ()=>location.href="/suppliermenustock?r="+
            (((this.getUrlParameter(window.location.href)).r)||"") 
          }>
            Adjust Stock</button>
          {dconnectState}
         {this.renderOrderList({status:"Incoming Orders"})}
         {this.renderOrderList({status:"In Progress"})}
         {this.renderOrderList({status:"Completed"})}
      </div>
    );
  }
}