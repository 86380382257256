import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./containers/navigation/home/Home";
import NotFound from "./containers/navigation/notfound/NotFound";
import Dashboard from "./containers/user/dashboard/Dashboard";
import TrainerTasks from "./containers/trainer/trainertasks/TrainerTasks";
import CommandPanel from "./containers/customers/commandpanel/CommandPanel";
import ConfirmPayment from "./containers/customers/confirmpayment/ConfirmPayment";
import CustomerOrders from "./containers/customers/customerorders/CustomerOrders";
import Questions from "./containers/customers/questions/questions";
import SignUp from "./containers/user/signup/SignUp";
import UserDetails from "./containers/user/userdetails/UserDetails";
import UserAddress from "./containers/user/useraddress/UserAddress";
import FinTrans from "./containers/robothub/financial/fintrans/FinTrans";
import Kiosks from "./containers/robothub/operations/kiosks/Kiosks";
//import Incoming from "./containers/Incoming";
import Incoming from "./containers/suppliers/supplierincoming/SupplierIncoming";
import pageWrapper from "./containers/objects/pageWrapper/pageWrapper";
import SupplierOrders from "./containers/suppliers/supplierorders/supplierOrders";
import CustomerReach from "./containers/suppliers/customerreach/CustomerReach";

const ProtectedRoute = ({ component: Component, authState, userDetails, ...rest }) => (
  <Route {...rest} render={(props) => (
    authState === "signedIn" && props.location.pathname === "/"
      ? <Redirect to={userDetails.route} />
     : authState === "signUp"
      ? <Redirect to='/signup' />
        : <Component {...props} authState={authState} userDetails={userDetails} />

     )} />
  )

export default ({ userProps }) => {
  //console.log(JSON.stringify(userProps.authState));

  var pathways=[
    {path:"/",component:Home},
    {path:"/dashboard",component:Dashboard},
    {path:"/trainertasks",component:TrainerTasks},
    {path:"/commandpanel",component:CommandPanel},
    {path:"/orders",component:CustomerOrders},
    {path:"/incoming",component:Incoming},
    {path:"/supplierorders",component:SupplierOrders},
    {path:"/useraddress",component:UserAddress},
    {path:"/userdetails",component:UserDetails},
    {path:"/reach",component:CustomerReach},
    {path:"/questions",component:Questions},
    {path:"/fintrans",component:FinTrans},
    {path:"/kiosks",component:Kiosks},
    {path:"/creditorinv",component:pageWrapper},
    {path:"/debtorinv",component:pageWrapper},
    {path:"/settingsconfig",component:pageWrapper},
    {path:"/suppliermenuitems",component:pageWrapper},
    {path:"/suppliermenugroups",component:pageWrapper},
    {path:"/settings",component:pageWrapper},
    {path:"/supplierhours",component:pageWrapper},
    {path:"/supplierreach",component:pageWrapper},
    {path:"/supplierstores",component:pageWrapper},
    {path:"/supplieraddons",component:pageWrapper},
    {path:"/suppliermenustock",component:pageWrapper},
    {path:"/ledgers",component:pageWrapper},
    {path:"/confirmpayment",component:ConfirmPayment},
    {path:'/signup',component:SignUp},
];
  var ProtectedRoutes=[];
  pathways.forEach(pathway=>{
    ProtectedRoutes.push(
      <ProtectedRoute path={pathway.path} exact 
      component={pathway.component} 
      authState={userProps.authState} 
      userDetails={userProps.userDetails} />
    );
  });

  return (
    <Switch>
      {ProtectedRoutes}
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  )
}
