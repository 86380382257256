/* eslint {eqeqeq:0,no-redeclare:0,no-unused-vars:0,no-useless-escape:0} */
import React from "react";
const jsFunc={
  damount: function(x){
    x=parseFloat(x).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },  
  ddatetime: function (date) {

    if(!date 
    || date=="0000-00-00 00:00:00"
    || date=="1970-01-01 00:00:00"
    ){date=this.cur_datetime();}else{
      //find time difference with local time and UTC, add onto function.
      var date = new Date(date);
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      new Date(date.getTime() - userTimezoneOffset);
    }
    return this.formatDate(date, "dd/MM/yyyy h:mm TT");
  },
  cur_datetime: function(){
    var date;
    date = new Date();
    //date.setHours( date.getHours() + 12 );
    return date;
  },
  formatDate: function (date, format, utc) {       
    if (date){
      var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
      function ii(i, len) {
          var s = i + "";
          len = len || 2;
          while (s.length < len) s = "0" + s;
          return s;
      }
      var y = utc ? date.getUTCFullYear() : date.getFullYear();
      format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
      format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
      format = format.replace(/(^|[^\\])y/g, "$1" + y);
  
      var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
      format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
      format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
      format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
      format = format.replace(/(^|[^\\])M/g, "$1" + M);
  
      var d = utc ? date.getUTCDate() : date.getDate();
      format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
      format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
      format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
      format = format.replace(/(^|[^\\])d/g, "$1" + d);
  
      var H = utc ? date.getUTCHours() : date.getHours();
      format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
      format = format.replace(/(^|[^\\])H/g, "$1" + H);
  
      var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
      format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
      format = format.replace(/(^|[^\\])h/g, "$1" + h);
  
      var m = utc ? date.getUTCMinutes() : date.getMinutes();
      format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
      format = format.replace(/(^|[^\\])m/g, "$1" + m);
  
      var s = utc ? date.getUTCSeconds() : date.getSeconds();
      format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
      format = format.replace(/(^|[^\\])s/g, "$1" + s);
  
      var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
      format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
      f = Math.round(f / 10);
      format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
      f = Math.round(f / 10);
      format = format.replace(/(^|[^\\])f/g, "$1" + f);
  
      var T = H < 12 ? "AM" : "PM";
      format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
      format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));
  
      var t = T.toLowerCase();
      format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
      format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));
  
      var tz = -date.getTimezoneOffset();
      var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
      if (!utc) {
          tz = Math.abs(tz);
          var tzHrs = Math.floor(tz / 60);
          var tzMin = tz % 60;
          K += ii(tzHrs) + ":" + ii(tzMin);
      }
      format = format.replace(/(^|[^\\])K/g, "$1" + K);
  
      var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
      format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
      format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);
  
      format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
      format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);
  
      format = format.replace(/\\(.)/g, "$1");
  
      return format;
    }else{
      return "";
    }
  },
  getUrlParameter: function(url) {
    var var_obj={};
    if(url.indexOf("?")>-1){
      var url_arr=url.split("?"); 
      var baseurl=url_arr[0].split("/");
      var_obj["route"]=baseurl[3];

      var var_arr=url_arr[1].split("&");
      var obj_single={};
      for (var i=0;i<var_arr.length;i++){
        obj_single=var_arr[i].split("="); var_obj[obj_single[0]]=obj_single[1];
      }
    }
    
    return(var_obj);
  },
  
  validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  
  display_buttons: function(buttonsModel) {
    var buttons=[];
    for(var i=0;i<buttonsModel.length;i++){
      var name=buttonsModel[i].name;
      var className=buttonsModel[i].className;
      var onclick=buttonsModel[i].onclick;
      var href=buttonsModel[i].href;

      if (!href){href="#";}
      if (!onclick){onclick="";}
      var classNameFull=" myButton "+className;
      buttons.push(
        <a className={classNameFull} href={href} onClick={onclick}>{name}</a>
      );
    }
    return(<div>{buttons}</div>);
  }
}
export default jsFunc;