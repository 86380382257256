/* eslint {eqeqeq:0,no-redeclare:0,no-unused-vars:0,no-useless-escape:0,no-loop-func:0} */
import React from "react";
import htmlInputs from './htmlInputs.js'; 
import cloneDeep from 'lodash/cloneDeep';
import { API } from "aws-amplify";
import jsFunc from './jsFunc.js'; 
import $ from 'jquery';
const pageFrame={
  render: function(prop){

    //var stateFrames=prop.stateFrames;
    var reactTableRef=prop.frame.reactTableRef;
    const frame=cloneDeep(prop.frame);
    var handleDomChange=prop.handleDomChange;
    var toggleRow=prop.toggleRow;
    var toggleCheckAll=prop.toggleCheckAll;
    //var reactTableRef=prop.reactTableRef;
    var pageType=prop.pageType;
    
    //format checkbox
    var rowCheckbox=(frame.checkbox)?{
      primaryID:frame.primaryID,
      frameName:"mainlist",
      checked:frame.checkSelected,
      checkAll:frame.checkAll,
    }:0;

    //var frames=[];
    //for (var i=0;i<stateFrames.length;i++){
      //const frame = cloneDeep(stateFrames[i]);
      //const frame = cloneDeep(stateFrames[i]);
      if(frame.isDisplay){
        var tableContent;
        if (frame.displayFormat=="reacttable"){
          var reacttable=htmlInputs.renderReacttable({
            frame,
            handleDomChange,
            rowCheckbox,
            toggleRow,
            toggleCheckAll,
            reactTableRef
          });
          tableContent=(<div>{reacttable}</div>);
        }
        if (frame.displayFormat=="horizontaltable"){    
          tableContent="horizontal Table";          
        }
        //other frame props
        //buttons
        var buttons=frame.buttons ? frame.buttons : [];
        var headerbuttons=[];
        buttons.forEach(button => { if(frame.isDisplay){
          var isDisplay=0;
          var actions=button.actions?button.actions:[];
          actions.forEach(action=>{            
            if(frame.action==action){isDisplay=1;}
          });

          if(isDisplay){
            headerbuttons.push(
              htmlInputs.button(
                {
                  class:button.class,
                  displayName:button.name,
                  func:button.onclick
                }
              )
            );
          }
        }});
        //subframes
        var contentSubFrames=[];
        var subFrames=frame.subFrames||[];
        for(var ii=0;ii<subFrames.length;ii++){          
          const subFrame = cloneDeep(subFrames[ii]);

          if(subFrame.type=="checklist"){

            if(frame.action=="read"){ 
              //remove all non-checked data from checklist
              var newData=[];
              subFrame.data.data.forEach(record=>{
              if(subFrame.dataChecked.includes(record[subFrame.primaryID])){
                newData.push(record);}});
              subFrame.data.data=newData;
            }
            
            var rowCheckbox="";
            if(frame.action=="edit"||frame.action=="create"){
              rowCheckbox={
                primaryID:subFrame.primaryID,
                frameName:subFrame.name,
                checked:subFrame.checkSelected,
                checkAll:subFrame.checkAll,
              }

            };
            //render reacttable list
            var reacttable=htmlInputs.renderReacttable({
              frame:{
                action:"read",
                data:subFrame.data
              },
              rowCheckbox,
              toggleRow,
              toggleCheckAll
            });
            
            contentSubFrames.push(reacttable);


          }

          if(subFrame.type=="sublist"){

            if(frame.action=="read"){ 
              //remove all non-checked data from checklist
            }

            //add new rows here
            if(frame.action=="edit"||frame.action=="create"){

            }
            
            //render reacttable list
            var reacttable=htmlInputs.renderReacttable({
              frame:{
                action:"read",
                data:subFrame.data
              },
            });
            
            contentSubFrames.push(reacttable);


          }

        }
        
        if(headerbuttons.length){
          headerbuttons=(<div style={
            {"marginBottom":"10px"}
          }>{headerbuttons}</div>);

        }

        return(
        <div>
          {headerbuttons}
          {tableContent}
          {contentSubFrames}
        </div>
        );
      }
    //}
    //return frames;
  },  
  updateFrame: function(prop){
    var name=prop.name;
    var showFrame=prop.showFrame;
    var parentID=prop.parentID;
    var selectedID=prop.selectedID;
    var action=prop.action;
    var frames=[];
    var stateFrames=prop.stateFrames;
    stateFrames.forEach(x => {
      var frame=x;
      if(frame.name==name){
        if(showFrame){frame.isDisplay=true;}
        if(parentID){frame.parentID=parentID;}
        if(selectedID){frame.selectedID=selectedID;}
        if(action){frame.action=action;}
      }else{
        if(showFrame){frame.isDisplay=false;}        
      }
      frames.push(frame);
    });
    return frames;    
  },
  
  clearFrameData: function(prop){
    
    var index=0;
    for(var i=0; i<prop.stateFrames.length;i++){
    if(prop.stateFrames[i].name==prop.name){index=i;}}; 
    var frames=prop.stateFrames;frames[index].data=[];
    return ({frames,index});

  },
  editrecord_exec: async (prop) => {
    var frames=prop.frames;
    var urlPost=prop.urlPost;
    var pageType=prop.pageType||"CRED";
    var post_arr=prop.post_arr||{};

    //send post variables
    //if(pageType=="CRED"){
      for(var i=0;i<frames.length;i++){
      if(frames[i].isDisplay){
        post_arr=frames[i].domVariables;
        //send subFrame data
        (frames[i].subFrames||[]).forEach(subFrame=>{
          if(subFrame.type=="checklist"){//send checklist
            post_arr[subFrame.name]=subFrame.checkSelected;}
        })

      }}
      console.log("post_arr");console.log(post_arr);
    //}
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;
    
    var roleid=urlvar["r"]?urlvar["r"]:0;
    //get frame data
    var frame;
    frames.forEach(x => {if(x.name=="recordDetail"){frame=x;}});    
    var urlpar={
      roleid,
      frame:frame.name,
      page:route,
      action:frame.action,
      selectedID:frame.selectedID
    };

    return await API.post('v1', urlPost+'?'+$.param(urlpar)+'&'+$.param(urlvar),
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      if(frame.action=="create"){return (result.newID);}
      else{return (frame.selectedID);}
      //this.renderDetails({id:frame.selectedID}); 
    });
  },
  toggleRecord_exec: async (prop) => {
    var urlPost=prop.urlPost;
    var post_arr=prop.post_arr||{};
    var selectedID=prop.selectedID;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;
    
    var roleid=urlvar["r"]?urlvar["r"]:0;
    var urlpar={
      roleid,
      frame:"recordDetail",
      page:route,
      action:"edit",
      selectedID
    };
    return await API.post('v1', urlPost+'?'+$.param(urlpar),
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      return (selectedID);
    });
  },
  defaultSettings_exec: async (prop) => {
    var urlPost=prop.urlPost;
    var post_arr=prop.post_arr||{};
    var selectedID=prop.selectedID;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;
    
    var roleid=urlvar["r"]?urlvar["r"]:0;
    var urlpar={
      roleid,
      frame:"recordDetail",
      page:route,
      action:"create",
      selectedID
    };
    return await API.post('v1', urlPost+'?'+$.param(urlpar)+'&'+$.param(urlvar),
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {

      //refresh main page
      return (selectedID);
    });
  },
  general_exec: async (prop) => {
    var post_arr=prop.post_arr||{};
    var frame=prop.frame;
    var func=prop.func;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    var route=urlvar.route;

    //send dom variables and checklist  
    //mainlist dom variables will on Action Bar
    //TBC
    
    var roleid=urlvar["r"]?urlvar["r"]:0;
    var urlpar={
      roleid,
      frame:"mainlist",
      page:route,func,
      action:"custom"
    };
    var post_arr={
      checkSelected:frame.checkSelected, //CHECKBOXLIST
    };
    return await API.post('v1', '/webapp?'+$.param(urlpar)+'&'+$.param(urlvar),
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {

      //refresh main page
      //return ();
    });
  },
    
}
export default pageFrame;