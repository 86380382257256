/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import ReactTable from "react-table";
import 'react-table/react-table.css'

import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import "./FinTrans.css";
import { API } from "aws-amplify";
import { isNullOrUndefined } from "util";
import htmlInputs from '../../../../func/htmlInputs.js'; 
import jsFunc from '../../../../func/jsFunc.js'; 
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";


const data = [
  {
    firstName: "judge",
    lastName: "babies",
    age: 16
  },
  {
    firstName: "quarter",
    lastName: "driving",
    age: 17
  },
  {
    firstName: "division",
    lastName: "society",
    age: 3
  },
  {
    firstName: "lamp",
    lastName: "point",
    age: 2
  },
  {
    firstName: "argument",
    lastName: "insurance",
    age: 13
  },
  {
    firstName: "pets",
    lastName: "fan",
    age: 27
  }
];
const columns = [
  {
    Header: "People list",
    columns: [
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        id: "lastName",
        accessor: d => d.lastName
      },
      {
        Header: "Age",
        accessor: "age"
      }
    ]
  }
];
var TransactionModel={
  ModelType:"Example Model",
  transactions:{
    data:data,
    columns:columns
  }
};

export default class FinTrans extends Component {

  constructor(props) {
    super(props);
    
    var selroleid;
    var urlvar=this.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}

    this.state = {
      selected: {}, 
      selectAll: 0,
      isLoading: true,
      fromDate: "01/01/2019",
      toDate : "31/05/2019",
      domVariables:[],
      Transactions : TransactionModel,
      transInputModel : []
    };
		this.toggleRow = this.toggleRow.bind(this); //bind Checkbox
  }
  async componentDidMount() {
    this.getFinTrans()
      .then((data) => {
        //add to DOM state
        let domVariables = {};
        data.transInputModel.forEach(x => {
        domVariables[x.dom_id]=x.defaultVal;});  
        this.setState({
          Transactions : data.fintrans,
          transInputModel : data.transInputModel,
          domVariables
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ isLoading: false });    
  }
  handleDomChange(event) {
    var domId = event.target.id;
    for(key in this.state.domVariables){
      if(key==domId){
      this.state.domVariables[key]=event.target.value;}
    };  
  }
	toggleRow(tranID) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[tranID] = !this.state.selected[tranID];
		this.setState({
			selected: newSelected,
			selectAll: 2
		});
  }  
	toggleSelectAll() {
		let newSelected = {};
		if (this.state.selectAll === 0) {
			this.state.Transactions.transactions.data.forEach(x => {
				newSelected[x.tranID] = true;
			});
		}
		this.setState({
			selected: newSelected,
			selectAll: this.state.selectAll === 0 ? 1 : 0
		});
	}

  
  getUrlParameter(url) {
    var var_obj={};
    if(url.indexOf("?")>-1){
      var url_arr=url.split("?"); var var_arr=url_arr[1].split("&");
      var obj_single={};
      for (var i=0;i<var_arr.length;i++){
        obj_single=var_arr[i].split("="); var_obj[obj_single[0]]=obj_single[1];
      }
    }
    return(var_obj);
  }
  
  getFinTrans = async event => {
    //return await API.get('dev-profile-service', '/useraddress');
    
    var selroleid=0;
    var urlvar=this.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}

    return await API.get('v1', '/fintrans?roleid='+selroleid);
  }

  reconcile_exe = async event => {
    return await API.post('v1', '/fintrans',
    {
      'Content-Type': 'application/json',
      body: {
        func:"reconcile",
        domVariables:this.state.domVariables,
        checked:this.state.selected
      }
    })
    .then(result => {
      console.log(JSON.stringify(result));

      this.getUserAddress()
      .then((data) => {
        this.setState({
          UserAddress : data
        });
      })
      .catch((err) => {
        console.log(err);
      });

      this.state.add_id_sel=-1;
      this.state.new_add_type="";
      //this.forceUpdate();
    });
    
  }
  
  render() {
    var data=this.state.Transactions.transactions.data;
    var columns=this.state.Transactions.transactions.columns;
    columns.unshift(
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original.tranID] === true}
              onChange={() => this.toggleRow(original.tranID)}
            />
          );
        },
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectAll === 1}
              ref={input => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45
      });

      //if (this.state.transactions.ModelType=="Example Model"){
      if (this.state.isLoading===true){
        return (
          <div className="table-wrapper">
            Please wait as we retrieve your records...
          </div>

        );
      }else{
        //add dom variables
        var htmlrows=[];
  			this.state.transInputModel.forEach(x => {
          var data_type=x.data_type || "string";
          
          var htmlinput=htmlInputs.textbox({
            defaultVal:x.defaultVal,
            onChange:(()=>this.handleDomChange)
          });
  			  htmlrows.push(<div>
            <div className="div_fieldname">{x.name}:</div> 
            <div className="div_fieldinput">{htmlinput}</div>
          </div>);
        });  
        var button=htmlInputs.button(
          {
            class:"myButton_blue",
            displayName:"Add Settlement",
            onClick:(()=>this.reconcile_exe)
          }
        );

        return (
        <div className="table-wrapper">
          {htmlrows}
          {button}
          <ReactTable
          data={data}
          columns={columns}
          />
        </div>
        );

      }

    /*
    return (
      <div>
        <div>
          Entering your addresses below helps Tom Push your Menu items to your Customers located in your area.
          It is good to ensure that you are able to service the customer in the areas you have listed.
        </div>
      </div>
    );
    */
  } 
}