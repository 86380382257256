/* eslint-disable */
import React, { Component } from "react";
import "./CommandPanel.css";
import { API } from "aws-amplify";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import UserLocations from '../../objects/userdetails/UserLocations.js'; 
import MenuOrders from '../../objects/orders/MenuOrders.js'; 
import htmlInputs from '../../../func/htmlInputs.js'; 
import jsFunc from '../../../func/jsFunc.js'; 

const CommandPanelModel = {"ModelType":"Initial Model",
"HoverSelection":{"frame1":[],"frame2":[],"frame3":[]},
"OptionSelection":[],
"userAddresses":[],
"ParSelection":[{"pt_id":"1","par_id":"2"},{"pt_id":"2","par_id":"2"}],
"PanelPar":[]
}

const inputModelDetails=[
  {"field_name":"Name","field_id":"firstName","validation":["notnull"]},
  {"field_name":"Email","field_id":"email","validation":["email","notnull"]},
  {"field_name":"Mobile","field_id":"mobile","validation":["notnull"]}
];
const inputModelBanks=[
  {
    "field_name":"Bank",
    "field_id":"bankName",
    "field_value":"WESTPAC",
    "input_prop":{
      "type":"dropdown",
      "selection":[
        {"value":"ASB","display":"ASB"},
        {"value":"WESTPAC","display":"Westpac"},
        {"value":"COOP","display":"Cooperative Bank"},
      ]
    }
  },
  /*
  {
    "field_name":"Autopay",
    "field_id":"oe_trusted",
    "field_value":"0",
    "input_prop":{
      "type":"checkbox",
    }
  }, */
];

//export default class CommandPanel extends Component {
export default class CommandPanel extends Component {

  constructor(props) {
    super(props);
    //console.log(JSON.stringify(props));
    //handle DOM variables
    var inputModelarr=[inputModelDetails,inputModelBanks];
    var checkboxState=[];
    for(var ii=0;ii<inputModelarr.length;ii++){ var inputModel=inputModelarr[ii];
      for (var i=0; i<inputModel.length; i++){  
        var inputtype="text";
        if(inputModel[i].input_prop){} //similar treatment for checkbox, DD and textbox
        this[inputModel[i]["field_id"]] = React.createRef(); 
        inputModel[i].inputRef=this[inputModel[i]["field_id"]];
      }
    }
    
    var isDev=false;
    if(window.location.href.indexOf("localhost")>-1){isDev=true;}
    if(window.location.href.indexOf("test.robot-hub")>-1){isDev=true;}    

    this.state = {
      isLoading: true,
      loadingStates: {"OnlineEftpos":"idle","POLI":"idle","stripe":"idle"},
      isDev,
      currentFrame : 1,
      priorFrame : 0,
      Paytype_Confirm: 0,
      Paytype_Method:0, //1-click, 2-online eftpos, 3-Stripe
      numCustomFunc : 0,
      expandedOptions : {"menuGroups":[]},
      frameText : {"frame2":"","frame3":"","frame4":""},
      confirm_obj : [{
        "text":"Confirm Request",
        "class":"td_confirm_inactive",
        "onclick_func":""}],
      CommandPanel : CommandPanelModel,
      checkboxState : checkboxState,
      UserDetails : {data:[]}
    };    
  }
  

  async componentDidMount() {

    //reload if authentication not done
    if (
      window.location.href.indexOf("#id_token")>-1
      || (window.location.href.indexOf("?code=")>-1)
    ){window.location.href=(window.location.href.split("?code="))[0];}

    this.getCommandPanel()
      .then((data) => {

        this.setState({
          CommandPanel: data
        });
        //load initial Task Category
        //var cf_id_initial=data.PanelSelections[0].cf_id;
        //this.sel_frame1(cf_id_initial,0);

      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ isLoading: false });
  }
  getCommandPanel = async event => {        
    return await API.get('v1', '/commandpanel?href='+window.location.href);
  }
  
  post_task_exe = async event => {
    
    //send order arr
    var order_arr=[];
    let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
    for (var i=0;i<OptionSelection.length;i++){
      var addon_arr=[];
      var add_ons=OptionSelection[i].add_ons;
      for (var ii=0;ii<add_ons.length;ii++){
        addon_arr.push({
          "mitem_id":add_ons[ii].mitem_id,
          "qty":add_ons[ii].qty
        });
      }
      order_arr.push({
        "mitem_id":OptionSelection[i].mitem_id,
        "item_num":OptionSelection[i].item_num,
        "qty":1,
        "addons":addon_arr
      });
    }



    //handle DOM variables
    //send bank selection
    //send User details
    var inputModelarr=[inputModelDetails,inputModelBanks];
    var post_arr={}; var field_value;var field_name; var field_id;
    var error_msg=[];
    for(var ii=0;ii<inputModelarr.length;ii++){ var inputModel=inputModelarr[ii];
      for (var i=0; i<inputModel.length; i++){  
        var inputtype="text";
        field_value=this[inputModel[i]["field_id"]].current.value;
        field_id=inputModel[i].field_id;
        field_name=inputModel[i].field_name;
        if(inputModel[i].validation){ // run validations
          var validation=inputModel[i].validation;
          for(var i_val=0; i_val<validation.length; i_val++){
            var val_type=validation[i_val];
            if (val_type=="notnull"){
              if(field_value==""){error_msg.push(field_name+" is mandatory. ");}
            }
            if (val_type=="email"){
              if(!jsFunc.validateEmail(field_value)){
                error_msg.push(field_name+" is not a valid Email. ");}
            }
          }

        }

        //handle input types for check box
        if(inputModel[i].input_prop){inputtype=inputModel[i].input_prop.type;}
        
        if(inputtype=="checkbox"){ //see react checkbox state
          field_value=0;
          if(this[inputModel[i]["field_id"]].current.checked){field_value=1;}
        }else{ //catchall general
        }
        post_arr[field_id]=field_value;
      }
    }
    //send additional variables
    post_arr["Paytype_Method"]=this.state.Paytype_Method; //send payment Method
    post_arr["href"]=window.location.href; //send current URL

    if(error_msg.length>0){
      var error_str="Hello Human. Please fix the following errors: ";
      for (var i=0;i<error_msg.length;i++){
        error_str+="\r\n("+(i+1)+") "+error_msg[i];}
      alert(error_str);
    }else{

      //change button to loading
      if (this.state.Paytype_Method==6){
        this.setState({loadingStates:{
        OnlineEftpos:"Sending Payment.. Please wait"}});}
      if (this.state.Paytype_Method==7){
        this.setState({loadingStates:{
        stripe:"Sending Payment.. Please wait"}});}
      if (this.state.Paytype_Method==9){
        this.setState({loadingStates:{
        POLI:"Sending Payment.. Please wait"}});}
  
      return await API.post('v1', '/commandpanel',
      {
        'Content-Type': 'application/json',
        body: {
          order_arr:order_arr,
          payment_prop:post_arr
        }
      })
      .then(result => {
          //online eftpos
          if (this.state.Paytype_Method==6){
            window.location.href='/confirmpayment?p='+result.paymentId;
          }
          //Stripe
          if (this.state.Paytype_Method==7){            
            //var stripe = Stripe('pk_test_J1qtyw7U0gpvcdtGdvGQk79o');
            var publicKey="pk_live_94hugjRiGsfLKp9hjX4rTi4D";
            if(window.location.href.indexOf("localhost")>-1){
              publicKey="pk_test_J1qtyw7U0gpvcdtGdvGQk79o";}

            var stripe = Stripe(publicKey);
            stripe.redirectToCheckout({
              sessionId: result.sessionID
            }).then(function (result) { 
              console.log(result.error.message);
            });
          }
          //click
          if (this.state.Paytype_Method==3){
          window.location.href = result.api_cred.topup_url;}

          //PoliPay
          if (this.state.Paytype_Method==9){
            window.location.href = result.api_cred.topup_url;}
          
          //if select Tom wallet - not enough credit request other option select
          if(!result.EnoughBalance){

          }
        console.log(JSON.stringify(result));
      });
    }
  }
  change_curlvl(framelvl){
    var item_num=this.state.CommandPanel.OptionSelectionSel;
    var error_msg="";if (item_num>0){error_msg=this.get_maxrequired_fulfilled(item_num);}
    if(error_msg!=""){alert(error_msg);}else{
    this.state.CommandPanel.OptionSelectionSel=0;
    this.state.Paytype_Confirm=0;
    this.state.currentFrame=framelvl;
    this.forceUpdate();
    }
  }
  renderFuncHeaderButton(obj){
    var button_class="";
    var img=obj.img;

    if(obj.class!=undefined){ button_class+=" "+obj.class+" "; }
    else { var button_class="myButton_cancel"; } //default
    if (obj.name=="Clear Order"){button_class=" myButton_del td_floatright ";}
    var framelvl=obj.framelvl;
    var pframelvl=obj.pframelvl||(framelvl-1);

    var is_changelvl_func=0;var is_submit_order=0; var paymethod=0;
    if(obj.function=="change_curlvl"){is_changelvl_func=1;}
    if(obj.function=="submit_order"){is_submit_order=1;}

    if(obj.function=="submit_order_oe"){paymethod=1;}
    if(obj.function=="submit_order_cc"){paymethod=2;}

    var button_func;
    if(is_changelvl_func==1){ //change current level
      button_func=(() => this.change_curlvl(framelvl));
    }else if(is_submit_order==1){ //Submit Order
      //button_func=(() => this.test_func());
      //check for default payment method, otherwise display methods
      button_func=(() => this.showPayMethods());
    }else{
      button_func=(() => this.clear_frame({framelvl,pframelvl}));
    }
    if(paymethod>0){ //post payment
      this.state.Paytype_Method=paymethod;
      button_func=(() => this.post_task_exe());
    }
    
    var dname=obj.name;
    
    if (img){
      var pathway="img/"+img;
      dname=(<img src={pathway} height="25" alt="Robot-Hub"/>);
      button_class="img_button";
    }

    return(<button align="right" onClick={button_func} 
      className={button_class}>{dname}</button>);

        
  }
  showPayMethods(){
    this.state.Paytype_Confirm=1;
    this.forceUpdate();
  }
  renderFuncHeader(prop){
    var dheader=prop.dheader;
    var class_head=prop.class_head;
    var style=prop.style;
    var buttons=prop.buttons;
    var img=prop.img;
    var button_arr=[];
    var dstyle=style || {};
    if(buttons!=undefined && buttons.length>0){
      for (var i=0; i<buttons.length; i++){
      button_arr.push(this.renderFuncHeaderButton(buttons[i]));}
    }
    return (
    <div className={class_head} style={dstyle}>
      {dheader} {button_arr}
    </div>); 
  }
  renderFramePar(){
    let OptionSelection=this.state.CommandPanel.OptionSelection;
    if(OptionSelection.length>0){
      var PanelPar=this.state.CommandPanel.PanelPar;
      let PanelParitems=[];
      for (var i=0;i<PanelPar.length;i++){
        var prop={
          "dheader":PanelPar[i]["Name"],
          "class_head":"td_par",
          "buttons":[]
        };
        PanelParitems.push(this.renderFuncHeader(prop));
        var ParSelection=PanelPar[i]["ParSelection"];
        var pt_id=PanelPar[i]["pt_id"];

        for (var ii=0;ii<ParSelection.length;ii++){
          ParSelection[ii]["pt_id"]=pt_id; //push pt_id to sub
          PanelParitems.push(this.renderActionsPar(ParSelection[ii]));
        }
      }
      return (
        <ListGroup>
        {PanelParitems}
        </ListGroup>
      );
    }
  }  
  renderActionsPar(action_obj){
    var par_name=action_obj.Name;
    var pt_id=action_obj.pt_id;
    var par_id=action_obj.par_id;
    
    let ParSelection=this.state.CommandPanel.ParSelection;
    var class_sel="td_action"; 
    for (var i=0;i<ParSelection.length;i++){
      if(pt_id==ParSelection[i]["pt_id"] && par_id==ParSelection[i]["par_id"]){
        class_sel+=" td_action_selected";
      }
    }
    //custom functions
    return (
      <ListGroupItem bsClass={class_sel} className={class_sel}  onClick={() => this.sel_par(pt_id, par_id)} >
        {par_name} 
      </ListGroupItem>
    ); 
  }
  renderFrame1(){
    let CommandPanel=this.state.CommandPanel;
    var userAddresses=CommandPanel.userAddresses;
    var PanelSelections=CommandPanel.PanelSelections;
    //show active addresses
    var dactiveAddress=UserLocations.active_locations({userAddresses:userAddresses});

    //sort on custom function
      let CommandPanelitems = [];
    if (PanelSelections!=undefined){
      PanelSelections.sort(function(a, b){
        var keyA = a.frametype;
        var keyB = b.frametype;
        // Compare the 2 dates
        if(keyA < keyB) return -1;
        if(keyA > keyB) return 1;
        return 0;
      });
      this.state.numCustomFunc=0;
      for (var i = 0; i < PanelSelections.length; i++) {
        if (i === 0) {
          var prop={
            "dheader":"Select Task",
            "class_head":"td_header",
            "buttons":[]
          };
          //CommandPanelitems.push(this.renderFuncHeader(prop));
        }

        //add custom function header
        var frametype = PanelSelections[i]["frametype"];
        var cus_func = this.state.numCustomFunc;
        if (frametype === 4) {
          cus_func += 1;
          this.state.numCustomFunc=cus_func;
          if (cus_func === 1) { //add cusfunc header
            //CommandPanelitems.push(this.renderFuncHeader("One-Click Requests","td_header"));
          }
        }
        if(PanelSelections[i].is_display){
        CommandPanelitems.push(this.renderActions1(PanelSelections[i]));}
      }
    }
    //Topup section
    var custbalance=0;var storeCredit=0;var color;
    this.state.CommandPanel.userDetails.data.forEach(d=>{
    if(d.field_id=="custbalance"){custbalance=d.field_value} 
    if(d.field_id=="storeCredit"){storeCredit=d.field_value} 
    });
    
    custbalance=0;//pending TBC
    color=custbalance<0?"red":"green";
    //find universal store
    var cf_id_wallet,cf_id_store;
    this.state.CommandPanel.PanelSelections.forEach(p=>{
    if (p.ft_sub_id==21){cf_id_wallet=p.cf_id;
      p.PanelSelections.forEach(pp=>{if (pp.ft_sub_id==71){
          cf_id_store=pp.cf_id;
        }
      });
    }});

    var wallet=(
      <div style={{
        "float":"left",
        "width":"50%",
        "paddingRight":"10px",
      }}>
        <div style={{"paddingBottom":"5px"}}>
          <h3 style={{"marginTop":"0px"}}>Wallet Balance</h3> 
          <span style={{color}}>${jsFunc.damount(custbalance)}</span>
          <div style={{
            "float":"right",
            "marginRight":"10px",
          }}>(<span style={{"cursor":"pointer"}} onClick={
            //()=>this.sel_frame1(cf_id_wallet, 0)
            ()=>this.sel_frame2({cf_id:cf_id_store,pframelvl:1})
            }>Top-up</span>)</div>          
        </div>
      </div>);
    color=storeCredit<0?"red":"green";
    var credit=(
      <div style={{
        "float":"left",
        "width":"50%",
        "paddingLeft":"10px",
      }}>
      <h3 style={{"marginTop":"0px"}}>Store Credit</h3>
      <span style={{color}}>${jsFunc.damount(storeCredit)}</span>
      </div>);
    var topupContent=(<div>{wallet}{credit}</div>);
    this.state.CommandPanel.userDetails.data.forEach(detail=>{
    if(detail.field_id=="userNo" && detail.field_value==10034){topupContent=(
      <div style={{
        "textAlign":"center",
        "width":"100%",
        "color":"green",
        }}>
        <div>Sign in to accumulate Store Credit </div>
        <div>and Online Wallet</div>
      </div>
    )}})
    var topup=(
      <div style={{
        "float":"left",
        "width":"100%",
        "marginTop":"5px",
        "borderTop":"1px solid #CCCCCC",
        "borderBottom":"1px solid #CCCCCC",
        "padding":"15px 0px",
        "width":"100%",
      }}>
        {topupContent}
      </div>

    );    

    //Intro Screen
    var intros=[
      {img:"img/select.png",wording:"1) Find cafe & select your order"},
      {img:"img/onlineeftposlogo.png",wording:"2) Pay Online or with Store Credit"},
      {img:"img/skipqueue.png",wording:"3) Skip the Queue, pickup in store"},
      {img:"img/gift.png",wording:"4) Collect 5% store credit on every purchase."},
    ];
    var introCat=[];
    intros.forEach(intro=>{
      introCat.push(
        <div style={{"float":"left", "width":"50%"}}>
          <div>
            <img src={intro.img} height="70" 
            style={{
              "marginLeft":"10px",
              "marginRight":"10px",
            }} alt="Robot-Hub"/>
          </div>
          <div style={{"margin":"20px"}}>{intro.wording}</div>
        </div>
      );
    });
    var introMsg=
      (<div style={{"textAlign":"center","color":"#666666",
      "marginTop":"15px","float":"left", "width":"100%"}}>
        {introCat}</div>);
    return ( 
    <div>{dactiveAddress}
    {CommandPanelitems}
    {topup}
    {introMsg}
    </div>
    );
  
  }  
  renderActions1(action_obj){
    var PanelSelections=action_obj.PanelSelections;
    var frametype=action_obj.frametype;
    var cf_id=action_obj.cf_id;    
    
    //hover selections
    let HoverSelection=this.state.CommandPanel.HoverSelection;
    var frame1_sel=HoverSelection.frame1;
    
    var class_sel="td_action td_pointer ";
    if (frame1_sel.includes(cf_id)){class_sel+=" td_action_selected";}
    else if (frame1_sel.length==0){}
    else{class_sel+=" td_tablet_hide";}

    //custom functions
    var dcus=""; if (frametype==4){dcus="(single-click)";}
    var num_arr=PanelSelections.length;
    var is_confirm=0; var morearrow=">";if(num_arr==0){morearrow="";is_confirm=1;} morearrow="";
    //morearrow=""; //replace with coloured single click
    /*
    return (
      <ListGroupItem as="li" bsClass="td_action" className={class_sel}  onClick={() => this.sel_frame1(cf_id, is_confirm)} >
        {action_obj.Name} {morearrow}
      </ListGroupItem>
    );
    */
   return (
    <div className="td_pointer"
    style={{
      "borderRight":"1px solid #CCCCCC",
      "borderBottom":"1px solid #CCCCCC",
      "borderTop":"1px solid #CCCCCC",
      "borderLeft":"1px solid #CCCCCC",
      "marginBottom":"10px",
    }}>
    <div 
    style={{
      "borderRight":"1px solid #999",
      "borderBottom":"1px solid #999",
    }}>
    <div 
    style={{
      "borderRight":"1px solid #666",
      "borderBottom":"1px solid #666",
    }}>
    <div 
    style={{
      "borderRight":"1px solid #333",
      "borderBottom":"1px solid #333",
      "color":"#FFFFFF",
      "padding":"15px",
      "font-size":"15pt",
      "font-weight": "bold",
      "background":"#0094FF",
      "textAlign":"center"
    }}
    onClick={() => this.sel_frame1(cf_id, is_confirm)} >
    {action_obj.Name} {morearrow} <span className="single-click">{dcus}</span> 
    </div></div></div></div>
  );
  }
  renderFrame2(){
    let CommandPanel=this.state.CommandPanel;
    var PanelSelections=CommandPanel.PanelSelections;
    let CommandPanelitems=[];
    var frame1_arr=CommandPanel.HoverSelection.frame1;
    var frameText=this.state.frameText.frame2;
    //for (var pty in PanelSelections){
    var firstsup=0;
    if (PanelSelections!=undefined){
    for (var i=0;i<PanelSelections.length;i++){
      var PanelSelections2=PanelSelections[i].PanelSelections;
      var mcf_id=PanelSelections[i]["cf_id"];
      if(frame1_arr.indexOf(mcf_id) != -1){
      if(firstsup==0){        
        var prop={
          "dheader":"",
          "class_head":"div_nav_button  td_web_hide ",
          "buttons":[{"name":"Back to Tasks","framelvl":1,
          "class":"myButton_cancel",
          //img:"back.png"
          }]
        };
        CommandPanelitems.push(this.renderFuncHeader(prop));
        var prop={
          //"dheader":""+frameText+" Nearby",
          "dheader":""+frameText,
          "class_head":"td_header",
          "buttons":[]
        };
        CommandPanelitems.push(this.renderFuncHeader(prop));
      }
      firstsup=1;
      //for (var pty2 in PanelSelections2){
      for (var ii=0;ii<PanelSelections2.length;ii++){
        CommandPanelitems.push(this.renderActions2(PanelSelections2[ii]));
    }}}}
   

  //var dactiveAddress=UserLocations.active_locations({
  //  userAddresses:this.state.CommandPanel.userAddresses});

   var div_class="";if(this.state.currentFrame>2){div_class="td_tablet_hide";}
   return (
    <div className={div_class}>
    {CommandPanelitems}</div>
  );
  }  
  renderActions2(action_obj){
    var PanelSelections=action_obj.PanelSelections;
    var mcf_id=action_obj.cf_id;
    var cf_id=action_obj.cf_id;    

    //hover selections
    let HoverSelection=this.state.CommandPanel.HoverSelection;
    var frame2_sel=HoverSelection.frame2;
    var class_sel="td_action td_pointer ";
    if (frame2_sel.includes(cf_id)){class_sel+=" td_action_selected";}
    else if (frame2_sel.length==0){}
    else {class_sel+=" td_tablet_hide";}
    
    //var num_arr=Object.keys(PanelSelections).length;
    var num_arr=PanelSelections.length;
    var is_confirm=0; var morearrow=">>";if(num_arr==0){morearrow="";is_confirm=1;}
    morearrow=""; //replace with coloured single click
    /*
    return ( 
    <ListGroupItem bsClass="td_action"  className={class_sel} onClick={() => this.sel_frame2(mcf_id, is_confirm)}>
      > {action_obj.Name} {morearrow} 
    </ListGroupItem>
    );*/
    return ( 
      <div className={class_sel} onClick={() => this.sel_frame2({cf_id:mcf_id, is_confirm})}>
        {this.supplier_display({
          name:action_obj.Name,
          location:action_obj.display_location,
          loyalty_rate:action_obj.loyalty_rate,
          storeCreditBal:action_obj.storeCreditBal
        })}
      </div>
      );
  }
  supplier_display(prop){
    var loyalty_rate=prop.loyalty_rate?(
      <div className='td_floatright'>
        <span className='span-sub' style={{"color":"#09995d"}}>
        {prop.loyalty_rate}% Loyalty
        </span>
      </div>
    ):"";

    var storeCreditBal=prop.storeCreditBal?(
      <div className=''>
        <span className='span-sub' style={{"color":"#09995d"}}>
        ${prop.storeCreditBal} Store Credit
        </span>
      </div>
    ):"";
    
    this.state.CommandPanel.userDetails.data.forEach(detail=>{
      if(detail.field_id=="userNo" && detail.field_value==10034){
        storeCreditBal=prop.loyalty_rate?(
          <div className=''>
          <span className='span-sub' style={{"color":"#09995d"}}>
          Sign in for store credit 
          </span></div>          
        ):"";
      }
    })

    

    return (  
    <div>
    {prop.name}
    <div className="td_floatright">
      <img src="img/gps.png" height="40" alt="Robot-Hub"/>
    </div>
    <div>
      <span className='span-sub'>{prop.location}</span>
    </div>
    {loyalty_rate}  
    {storeCreditBal}  
    
    </div>);
  }
  toggleMenuGroup_select(prop){
    var categoryName=prop.categoryName;
    var expandedOptions=this.state.expandedOptions.menuGroups;
    
    var expandedOptionsNew=[];
    if(expandedOptions.indexOf(categoryName) != -1){ 
    //displayed - remove from state
      for (var expandedOption in expandedOptions){
        if (expandedOption!==categoryName){
        expandedOptionsNew.push(expandedOption);}
      }
    }else{ //add to state
      expandedOptionsNew=expandedOptions;
      expandedOptionsNew.push(categoryName);
    }
    this.setState({
      expandedOptions:{menuGroups:expandedOptionsNew}
    });
  }
  renderFrame3(){
    let CommandPanel=this.state.CommandPanel;
    var Tasks=CommandPanel.PanelSelections;
    var suppliersSelected=CommandPanel.HoverSelection.frame2;    

    var totalprice=this.fetch_total_orderprice();
    var dtotal="";if (totalprice>0){dtotal=" (Order $"+jsFunc.damount(totalprice)+")";}

    let CommandPanelitems=[];
    if(Tasks!=undefined){
    for (var i=0;i<Tasks.length;i++){
      var suppliers=Tasks[i].PanelSelections;
      for (var ii=0;ii<suppliers.length;ii++){  
        var MenuItems=suppliers[ii].PanelSelections;

        var supplierName=suppliers[ii].Name;
        var supplierLocation=suppliers[ii].display_location;
        var loyalty_rate=suppliers[ii].loyalty_rate;
        var storeCreditBal=suppliers[ii].storeCreditBal;

        var mcf_id=suppliers[ii]["cf_id"];
        if(suppliersSelected.indexOf(mcf_id) != -1){
          if (MenuItems.length>0){  
            
            //nav items
            var button_arr=[];
            //BACK BUTTON
            if(this.state.priorFrame==2){ 
              button_arr.push({"name":"Back to Locations","framelvl":2, 
              "class":"myButton_cancel",//img:"back.png"
              });
            }else if (this.state.priorFrame==1){
              button_arr.push({"name":"Back to Tasks","framelvl":1,
              "class":"myButton_cancel",//img:"back.png"
              });
            }
            //GO TO ORDER BUTTON
            let OptionSelection=this.state.CommandPanel.OptionSelection;
            if(OptionSelection.length>0){
              button_arr.push(
              {"name":"Your Order","function":"change_curlvl",
              "framelvl":4,"class":"td_floatright myButton_new"})
            }
            var prop={
              "dheader":"",
              "class_head":"div_nav_button  td_web_hide ",
              "buttons":button_arr
            };
            CommandPanelitems.push(this.renderFuncHeader(prop));

            //main header
            var prop={
              "dheader":"Menu "+dtotal,
              "class_head":"td_header",
              "buttons":[
                //{"name":"Your Order","function":"change_curlvl","framelvl":4},
                //{"name":"Clear Order","framelvl":3},
                //{"name":"Back","framelvl":2}
              ]
            };
            CommandPanelitems.push(this.renderFuncHeader(prop));

            CommandPanelitems.push(
              <div className="td_action_highlighted" style={{
                'margin-top':'10px',
                'margin-bottom':'10px'}}>
              {this.supplier_display({
                name:supplierName,
                location:supplierLocation,
                loyalty_rate,storeCreditBal
              })}
              </div>
            );
          }          
          //menu categories
          var category_name_prior="";var category_name="";var categoryId;
          for (var iii=0;iii<MenuItems.length;iii++){
            //add category header
            category_name=MenuItems[iii].menu_category_name;
            categoryId=MenuItems[iii].menu_category_id;
            const categoryName=category_name;
            if(category_name_prior!=category_name){
              CommandPanelitems.push(MenuOrders.renderMenuCategory({
                name:category_name,
                categoryId,
                expandedOptions:this.state.expandedOptions.menuGroups,
                toggleFunc:(() => this.toggleMenuGroup_select({categoryName}))
              }));
            }
            category_name_prior=category_name;
            
            //show if category is exapnded
            if(this.state.expandedOptions.menuGroups.indexOf(category_name) != -1){

              CommandPanelitems.push(this.renderActions3(MenuItems[iii]));
              //render group items that have is_displaymain of 1
              var option_obj=MenuItems[iii];
              if (option_obj.PanelSelections!=undefined){
              for (var j=0; j<option_obj.PanelSelections.length; j++){
              if(option_obj.PanelSelections[j].is_maindisplay==1 
              || option_obj.PanelSelections[j].is_maindisplay==3){ //show group
                var addonSelection=option_obj.PanelSelections[j].PanelSelections||[];
              for (var jj=0; jj<addonSelection.length; jj++){
                var addon_obj=addonSelection[jj];
                CommandPanelitems.push(this.renderMainAddon(addon_obj,option_obj));
              }}}}
            }

          }
    }}}}
    /*
    return (
      <ListGroup className="listgroup3">
      {CommandPanelitems}
      </ListGroup>
    );*/
    return (
      <div>{CommandPanelitems}</div>
    );
  }
  renderActions3(option_obj){
    var cf_id=option_obj.cf_id;
    var option_desc=option_obj.ft_sub_id_desc
    var price=option_obj.Price;
    
    if(option_desc==null || option_desc==undefined){option_desc="";}

    var dprice="";if(price!=null && price!=undefined && price!=0){
    dprice="$"+jsFunc.damount(price);}

    //check if is_maindispayed exists for any groups
    var display_ordered=1;var curnum=0; var to_expand=0;
    let OptionSelection=this.state.CommandPanel.OptionSelection;
    if(option_obj.PanelSelections!=undefined){
    for(var i=0;i<option_obj.PanelSelections.length;i++){ //groups
    if(option_obj.PanelSelections[i].is_maindisplay==1){display_ordered=0;} //always display
    else if(option_obj.PanelSelections[i].is_maindisplay==2){to_expand=1;} //expandable
    else if(option_obj.PanelSelections[i].is_maindisplay==3){to_expand=2;display_ordered=0;} //contractable
    }}
    if(display_ordered==1){
    for (var i=0;i<OptionSelection.length;i++){
      if(OptionSelection[i]["cf_id"]==cf_id){curnum++;}
    }}
    
    var dcurnum=" (x"+curnum+") ";if (curnum==0){dcurnum="";}
    var class_sel="td_action"; //if (curnum>0){class_sel+=" td_action_selected";}
    
    //expandable display
    var option_class=""; var dexpand="";var onclick_func="";
    if(to_expand==1){
      dexpand=" +";option_class=" span-button ";
      var onclick_func=(() => this.ExpandOption(option_obj,3));
    }else if(to_expand==2){
      dexpand=" -";option_class=" span-button ";
      var onclick_func=(() => this.ExpandOption(option_obj,2));
    }
    //dexpand="";

    var default_sel={}; var button_order=[];
    var num_maxrequired=this.get_num_maxrequired(cf_id).num_maxrequired;
    var cus_mark="";if(num_maxrequired>0){cus_mark="*";}cus_mark="";
    button_order.push(
      <button className='myButton_edit td_floatright' 
      onClick={() => this.add_menu_qty(option_obj,default_sel)}>{cus_mark}Add</button>);

    return (
    <div className={class_sel}>
      <div>
        <span className={option_class} onClick={onclick_func}>
          {option_obj.Name}
          <span className='span-sub'>{dexpand}</span>
        </span>
        <span className='span-order'>{dcurnum} </span>
        {button_order}
        <span className='span-price'>{dprice}</span>
      </div>
      <span className='span-sub'>{option_desc}</span>
    </div>);
  }
  ExpandOption(option_obj,is_maindisplay){
    var PanelSelections=this.state.CommandPanel.PanelSelections;    
    for (var i=0;i<PanelSelections.length;i++){ var PanelSelections2=PanelSelections[i].PanelSelections;
    for (var ii=0;ii<PanelSelections2.length;ii++){ var PanelSelections3=PanelSelections2[ii].PanelSelections;
    for (var iii=0;iii<PanelSelections3.length;iii++){ var option_obj2=PanelSelections3[iii];
    if(option_obj.cf_id==option_obj2.cf_id){if(option_obj2.PanelSelections!=undefined){
      for(var i_group=0;i_group<option_obj.PanelSelections.length;i_group++){ //groups
        if(option_obj.PanelSelections[i_group].is_maindisplay==1 
          || option_obj.PanelSelections[i_group].is_maindisplay==2
          || option_obj.PanelSelections[i_group].is_maindisplay==3
        ){
        this.state.CommandPanel.PanelSelections[i].PanelSelections[ii].PanelSelections[iii].PanelSelections[i_group].is_maindisplay=is_maindisplay;}
    }}}}}}
    this.forceUpdate();
  }
  renderMainAddon(addon_obj,option_obj){    
    var cf_id=addon_obj.cf_id;
    var option_desc=addon_obj.ft_sub_id_desc
    var price=addon_obj.Price;
    let OptionSelection=this.state.CommandPanel.OptionSelection;
    
    if(option_desc==null || option_desc==undefined){option_desc="";}

    var dprice="";if(
      price!=null 
      && price!=undefined
      && price!=0
      ){
    dprice="$"+jsFunc.damount(price);}
    
    //check if is_maindispayed exists for any groups
    var display_ordered=0;var curnum=0;
    if(option_obj.PanelSelections!=undefined){
    for(var i=0;i<option_obj.PanelSelections.length;i++){
      if(option_obj.PanelSelections[i].is_maindisplay==1){display_ordered=1;}
      if(option_obj.PanelSelections[i].is_maindisplay==3){display_ordered=1;}
    }}
    if(display_ordered==1){
    for (var i=0;i<OptionSelection.length;i++){if (option_obj.cf_id==OptionSelection[i].cf_id){
    for (var ii=0;ii<OptionSelection[i].add_ons.length;ii++){
    if(OptionSelection[i].add_ons[ii].cf_id==cf_id){curnum++;} //curnum+=OptionSelection[i].add_ons[ii].qty;
    }}}}

    var dcurnum=" (x"+curnum+") ";if (curnum==0){dcurnum="";}
    var class_sel="td_action_sub";
    class_sel+="  ";
    //if (curnum>0){class_sel+=" td_action_selected";}
    
    var default_sel={
      "qty":1,
      "addons":[{
        "cf_id":addon_obj.cf_id,
        "qty":1
      }]
    };

    var button_order=[];
    button_order.push(
      <button className='myButton_edit td_floatright' 
      onClick={() => this.add_menu_qty(option_obj,default_sel)}>Add</button>);
    //check if option has more than one group, add +Cus Button

    return (
    <div className={class_sel}>
      {addon_obj.Name} 
      <span className='span-sub'> {option_desc}</span> 
      <span className='span-order'> {dcurnum} </span>
      {button_order}
      <span className='span-price'> {dprice}</span>

    </div>);

  }
  fetch_total_orderprice(){
    let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
    var totalprice=0;
    //Find Addons to the order if any
    for (var i=0;i<OptionSelection.length;i++){
        var add_ons=OptionSelection[i].add_ons;
        var itemprice=OptionSelection[i].cf_price;
        if (itemprice!=null){totalprice+=itemprice;}

        for (var ii=0;ii<add_ons.length;ii++){
          if (add_ons[ii].cf_price!=null){
          totalprice+=add_ons[ii].cf_price;}
        }
    }
    return totalprice;
  }
  selectPayMethod(prop){
    const PayMethod=prop.PayMethod;
    this.state.Paytype_Method=PayMethod;
    this.setState({Paytype_Method:PayMethod}); //doesn't work at exe..
    this.post_task_exe();
  }
  renderFrame4(){
    let CommandPanel=this.state.CommandPanel;
    var PanelSelections=CommandPanel.PanelSelections;
    var frame2_arr=CommandPanel.HoverSelection.frame2;
    let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
    
    var totalprice=this.fetch_total_orderprice();
    var dtotal="";if (totalprice>0){dtotal=" ($"+jsFunc.damount(totalprice)+")";}

    //render current options selected
    let CommandPanelitems=[];
    var item_num_sel=this.state.CommandPanel.OptionSelectionSel;   
    //for (var pty in PanelSelections){
    if(PanelSelections!=undefined){
    for (var i=0;i<PanelSelections.length;i++){
      var PanelSelections2=PanelSelections[i].PanelSelections;
      for (var ii=0;ii<PanelSelections2.length;ii++){  
        var PanelSelections3=PanelSelections2[ii].PanelSelections;
        var mcf_id=PanelSelections2[ii]["cf_id"];
        if(frame2_arr.indexOf(mcf_id) != -1){ optionloop:
          for (var iii=0;iii<PanelSelections3.length;iii++){
            var cf_id_cur=PanelSelections3[iii]["cf_id"]
            //loop through current selections
            for (var i_opt=0;i_opt<OptionSelection.length;i_opt++){
              //print header
              if (i_opt==0 && iii==0){
                var but_arr=[];
                if(item_num_sel==0 && this.state.Paytype_Confirm==0){
                  but_arr.push(
                    {"name":"Make Payment",
                    "function":"submit_order",
                    "framelvl":4,
                    "class":"td_floatright myButton_new"});
                }
                but_arr.push({
                  "name":"Back to Menu","function":"change_curlvl",
                  "framelvl":3,"class":"myButton_cancel td_web_hide",
                  //img:"back.png"
                  }
                );
                var prop={
                  "dheader":"",
                  "class_head":"div_nav_button",
                  "buttons":but_arr
                };
                CommandPanelitems.push(this.renderFuncHeader(prop));

                if(this.state.Paytype_Confirm==1){

                  //user Details
                  var prop={
                    "dheader":"Your Details",
                    "class_head":"td_header",
                    "buttons":[]
                  };
                  CommandPanelitems.push(this.renderFuncHeader(prop));                  
                  var userDetails=htmlInputs.input_rows({
                    model:this.state.CommandPanel.userDetails,
                    action:"edit",
                    inputModel:inputModelDetails,
                    buttons_footer:[]
                  });                                 
                  var class_sel="td_action"; 
                  CommandPanelitems.push(
                  <div>
                      <div className={class_sel}>{userDetails}
                      </div>
                  </div>
                  );

                  //Payment Methods
                  var prop={
                    "dheader":"Select Payment Method",
                    "class_head":"td_header",
                    "style":{"margin-top":"20px"},
                  };
                  CommandPanelitems.push(this.renderFuncHeader(prop));
                  
                  //online eftpos                  
                  var bankSelection=htmlInputs.input_rows({
                    model:{data:inputModelBanks},
                    action:"edit",
                    inputModel:inputModelBanks,
                    buttons_footer:[]
                  });
                  var onclick_func;
                  onclick_func=(() => this.selectPayMethod({PayMethod:6}));                  
                  var class_sel="td_action"; 
                  
                  var select_but="";
                  if(this.state.loadingStates.OnlineEftpos=="idle"){
                    select_but=(<button onClick={onclick_func} 
                      className='myButton_edit td_floatright'>
                        Pay with Online Eftpos</button>);
                  }else{
                    select_but=(<button  
                      className='myButton_edit td_floatright'>
                      {this.state.loadingStates.OnlineEftpos}</button>);
                  }
                  CommandPanelitems.push(
                  <div>
                      <div className={class_sel}>
                      <p><img src="img/onlineeftpos.png" height="70" alt="Robot-Hub"/></p>
        
                        <div>
                          Complete on Mobile App
                          <div><span className='span-sub'>Compatible Banks only</span></div>
                          {bankSelection}
                        </div>
                        {select_but}
                      </div>
                  </div>
                  );

                  //PoliPay
                  onclick_func=(() => this.selectPayMethod({PayMethod:9}));                  
                  var class_sel="td_action"; 
                  var select_but="";
                  if(this.state.loadingStates.POLI=="idle"){
                    select_but=(<button onClick={onclick_func} 
                      className='myButton_edit td_floatright'>
                        Pay with POLi</button>);
                  }else{
                    select_but=(<button  
                      className='myButton_edit td_floatright'>
                      {this.state.loadingStates.POLI}</button>);
                  }

                  CommandPanelitems.push(
                  <div className="margin_bottom">
                      <div className={class_sel}>
                        <div>
                          <p><img src="img/poli.png" height="70" alt="Robot-Hub"/></p>
                          <div>
                            <a href="http://www.polipayments.com/buy" 
                            target="_blank">Learn more about POLi</a> 
                          
                          </div>
                          <div>
                          <span className='span-sub'>
                            <a href="https://transaction.apac.paywithpoli.com/POLiFISupported.aspx" 
                            target="_blank">Available Banks for POLi</a> 
                          </span>
                          </div>
                          {select_but}
                        </div>
                      </div>
                  </div>
                  );          
                  
                  //Stripe - Credit Card
                  onclick_func=(() => this.selectPayMethod({PayMethod:7}));                  
                  var class_sel="td_action"; 
                  var select_but;
                  if(this.state.loadingStates.stripe=="idle"){
                    select_but=(<button onClick={onclick_func} 
                      className='myButton_edit td_floatright'>
                        Pay with Credit Card</button>);
                  }else{
                    select_but=(<button 
                     className='myButton_edit td_floatright'>
                       {this.state.loadingStates.stripe}</button>);
                  }

                  CommandPanelitems.push(
                  <div className="margin_bottom">
                      <div className={class_sel}>
                        <div>
                          <p><img src="img/stripe.png" height="70" alt="Robot-Hub"/></p>
                          Credit Card Payments
                          <div>
                          <span className='span-sub'>
                            Mastercard, Visa, Wechat Pay, Alipay,
                            Google Pay, Apple Pay
                          </span>
                          </div>
                          {select_but}
                        </div>
                      </div>
                  </div>
                  );          

                  if(1==2){
                  //Tom Wallet
                  onclick_func=(() => this.selectPayMethod({PayMethod:8}));                  
                  var class_sel="td_action"; 
                  var select_but=(<button onClick={onclick_func} className='myButton_edit td_floatright'>Use Existing Balance</button>);
                  CommandPanelitems.push(
                  <div className="margin_bottom">
                      <div className={class_sel}>
                        <div>
                        Pay with your Online Wallet
                          <div>
                          <span className='span-sub'>Balance $0.00</span>
                          </div>
                          {select_but}
                        </div>
                      </div>
                  </div>
                  );
                  }  
                  /* 
                  // CLICK
                  onclick_func=(() => this.selectPayMethod({PayMethod:3}));                  
                  var class_sel="td_action"; 
                  var select_but=(<button onClick={onclick_func} className='myButton_edit td_floatright'>Select</button>);
                  CommandPanelitems.push(
                  <div className="margin_bottom">
                      <div className={class_sel}>
                        <div>
                        Paymark CLICK
                          <div>
                          <span className='span-sub'>(Online EFTPOS, Mastercard, Visa)</span>
                          </div>
                          {select_but}
                        </div>
                      </div>
                  </div>
                  );                        
                  */                 
                  break optionloop; //exit loop
                }
                if(!item_num_sel){ //only show if not edit
                CommandPanelitems.push(this.renderFuncHeader({
                  "dheader":"Your Order"+dtotal,
                  "class_head":"td_header",
                }));
                }
              }

              
              if (cf_id_cur==OptionSelection[i_opt].cf_id){
                var sel_obj=OptionSelection[i_opt];
                var option_obj=PanelSelections3[iii];
                var item_num=sel_obj.item_num;
                //print order
                if(item_num_sel==0 || item_num_sel==item_num){
                CommandPanelitems.push(this.renderActions4(sel_obj,option_obj));
                //Show order addons
                if(item_num_sel==item_num){ //if currently selected
                if(option_obj.PanelSelections!=undefined){//show groupings
                for (var i_group=0;i_group<option_obj.PanelSelections.length;i_group++){
                  //group header
                  var group_obj=option_obj.PanelSelections[i_group];
                  CommandPanelitems.push(this.renderOptionSelGroup(sel_obj,group_obj));
                  //show groupings
                  if(group_obj.PanelSelections!=undefined){
                  for (var i_sel=0;i_sel<group_obj.PanelSelections.length;i_sel++){
                    CommandPanelitems.push(this.renderOptionSel(sel_obj,group_obj.PanelSelections[i_sel]));
                  }}
                }}}}
              }
            }   
    }}}}}
    return (
      <div>{CommandPanelitems}</div>
    );
  }  
  renderActions4(sel_obj,option_obj){ //order frame - shows seleted objs
    var item_num=sel_obj["item_num"];
    var consumer_name=sel_obj["consumer_name"];
    var item_num_sel=this.state.CommandPanel.OptionSelectionSel;   
    var dcusname;
    if (consumer_name=="" || consumer_name!=undefined){dcusname="";}
    else{dcusname="("+consumer_name+")";}    
    var dcurnum; dcurnum=" (#"+item_num+")"; //dcurnum=""; //item number
    

    //always show summary
    //get total item price
    var totalprice=option_obj.Price; var dtotalprice="";
    let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
    for (var i=0;i<OptionSelection.length;i++){
    if(OptionSelection[i].item_num==item_num){
    var add_ons=OptionSelection[i].add_ons;
    for (var ii=0;ii<add_ons.length;ii++){
      totalprice=totalprice+add_ons[ii].cf_price;
    }}}
    if(totalprice!=null && totalprice!=undefined && totalprice>0){
    dtotalprice="$"+jsFunc.damount(totalprice);}

    var addon_summary=this.get_menuitem_summary(item_num);  
    var div_class="td_tablet_hide";if(item_num_sel==item_num 
      || item_num_sel==null
      || item_num_sel==0
      ){div_class="";} div_class="";
    
    var onclick_func=(() => this.sel_order(item_num));
    var onclick_func_del=(() => this.sel_order_del(item_num));
    
    var class_sel="td_action"; 
    var edit_but=(<button onClick={onclick_func} className='myButton_edit td_floatright'>Edit</button>);
    var del_but=(<button onClick={onclick_func_del} className='myButton_del td_floatright but_margin'>X</button>);
    
    //no edit button if no groupings
    if(option_obj.PanelSelections==undefined 
      || option_obj.PanelSelections.length==0){
      edit_but=(<button className='myButton_inactive td_floatright'>Edit</button>);
      edit_but="";
    }
    
    if(item_num_sel==item_num){
      class_sel="td_action_highlighted"; 
      edit_but=(<button onClick={onclick_func} className='myButton_green td_floatright'>Done</button>);
      del_but=(<button onClick={onclick_func_del} className='myButton_del td_floatright but_margin'>X</button>);
    }
    
    return (
    <div className={div_class}>
        <div className={class_sel}>
          <div>
            {option_obj.Name} <span className='span-order'>{dcusname} {dcurnum}</span>
            {del_but} {edit_but}
            <span className='span-price'>{dtotalprice}</span>
          </div>
          <span className='span-sub'>{addon_summary}</span>
        </div>
    </div>
    );
  }
  get_menuitem_summary(item_num){
      //Find Addons to the order if any
      let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
      //sort on custom function
      OptionSelection.sort(function(a, b){
        var keyA = a.group_id;
        var keyB = b.group_id;
        // Compare the 2 dates
        if(keyA < keyB) return -1;
        if(keyA > keyB) return 1;
        return 0;
      });
      var pgroup_id; var summary_arr=[];
      for (var i=0;i<OptionSelection.length;i++){
      if(OptionSelection[i].item_num==item_num){
          var add_ons=OptionSelection[i].add_ons;
          for (var ii=0;ii<add_ons.length;ii++){
            var group_id=add_ons[ii].group_id;
            var group_name=add_ons[ii].group_name;
            var add_on_name=add_ons[ii].cf_name;            
            var add_on_qty=add_ons[ii].qty;
            var dadd_on_name=add_on_name;

            //add group header
            if (pgroup_id!=group_id){
              var counter=summary_arr.length;
              summary_arr.push({
                "group_id": group_id,
                "group_name": group_name,
                "group_listing": []
              });
            }
            pgroup_id=group_id;
            //add addon to group
            summary_arr[counter].group_listing.push({
              "name":add_on_name,
              "qty":add_on_qty
            })
          }
      }}
      var addon_summary=[];
      for (var i_sum=0;i_sum<summary_arr.length;i_sum++)
      {
        var listings="";
        var header_name=summary_arr[i_sum].group_name;
        var group_listing=summary_arr[i_sum].group_listing;
        for (var i_list=0;i_list<group_listing.length;i_list++)
        {
          var add_on_qty=group_listing[i_list].qty;
          var add_on_name=group_listing[i_list].name;
          var dadd_on_name=add_on_name;
          if (add_on_qty>1){dadd_on_name+=" x"+add_on_qty;}
          var cm="";if (i_list>0){cm=", ";}
          dadd_on_name=cm+dadd_on_name;
          listings+=dadd_on_name;
        }
        addon_summary.push(
          <div>
            {header_name}: {listings}
          </div>
        );
      }
      return addon_summary;
  }
  renderOptionSelGroup(sel_obj,group_obj){
    var item_num=sel_obj.item_num;
    var max_qty=group_obj.max_qty;
    var group_id=group_obj.ft_sub_id;
    var option_desc=group_obj.ft_sub_id_desc;
    var max_qty_required=group_obj.max_qty_required;
    
    if(option_desc==null || option_desc==undefined){option_desc="";}
    
    var dmax; var dcompul="";
    var dmax_qty=""; if(max_qty!=0 && max_qty!=null){
      if(max_qty_required==0){dmax="Max";}
      else{dcompul="*";dmax="Choose";}
      dmax_qty=" ("+dmax+" "+max_qty+")";
    }
    

    var clear="Clear Selection"; clear="X"; clear="Clear";//if (framelvl==0){clear="";}    
    var dclear=(
    <button align="right" onClick={() => this.clear_frame_group(group_id, item_num)} 
    className='myButton_grey td_floatright'>
    {clear}
    </button>
    ); 

    return(
      <div className='td_par2_sub'>
        {group_obj.Name}{dcompul} {dmax_qty} {dclear}
        <span className='span-sub'>{option_desc}</span>
      </div>);
  }
  renderOptionSel(sel_obj,groupsel_obj){

    var add_ons=sel_obj.add_ons;
    var item_num=sel_obj.item_num;    
    var is_inc=0; var sel_qty=0;
    var cf_id=groupsel_obj.cf_id;
    var price=groupsel_obj.Price;
    var option_desc=groupsel_obj.ft_sub_id_desc;
    var Name=groupsel_obj.Name;

    if(option_desc==null || option_desc==undefined){option_desc="";}
    
    var dprice="";if(price!=null && price!=0){dprice="$"+jsFunc.damount(price);}
    
    
    //loop current selections
    for (var ii=0;ii<add_ons.length;ii++){
      if(cf_id==add_ons[ii].cf_id){
        is_inc=1;
        sel_qty=add_ons[ii].qty;
      }
    }
    //var class_sel=" td_action2 sub_margin ";
    var class_sel=" td_action_sub td_pointer ";
    if (is_inc==1){class_sel+=" td_action_selected";}
    var dqty="";if (sel_qty>1){dqty=" (x"+sel_qty+")";}        
    return(
      <div className={class_sel} onClick={() => this.add_menu_sel(item_num, groupsel_obj)}>
        {Name} <span className='span-sub'>{option_desc}</span>        
        {dqty} <span className='span-price'>{dprice}</span>
      </div>
    );

  }


  test_func() {alert("hello");}

  sel_oneclick(){
    //select one clicks
    //this.forceUpdate();
  }
  sel_par(pt_id,par_id){
    
    let ParSelection=this.state.CommandPanel.ParSelection;
    var is_sel=0;
    for (var i=0;i<ParSelection.length;i++){
      if(ParSelection[i]["pt_id"]==pt_id){
        is_sel=1;
        ParSelection[i]["par_id"]=par_id;
      }
    }
    if (is_sel === 0) { ParSelection.push({ "pt_id": pt_id, "par_id": par_id }); }
    this.state.CommandPanel.ParSelection=ParSelection;
    this.forceUpdate();
  }
  sel_frame1(cf_id, is_confirm) {
    this.state.CommandPanel.OptionSelection=[];//reset options
    this.state.CommandPanel.HoverSelection.frame2=[];
    var scf_id = this.state.CommandPanel.HoverSelection.frame1;
    if (scf_id === cf_id) {//unselect if already selected
      this.state.CommandPanel.HoverSelection.frame1=[];
      this.request_confirm_order(0); //clear confirm if unselect
    } else {

      this.state.currentFrame=2; //set frame state

      //get frame1 name
      var frameText="";
      var PanelSelections=this.state.CommandPanel.PanelSelections;
      for (var i=0;i<PanelSelections.length;i++){
        if(PanelSelections[i].cf_id==cf_id){frameText=PanelSelections[i].Name;}
      }
      this.state.frameText.frame2=frameText;

      this.state.CommandPanel.HoverSelection.frame1=[cf_id];
      this.request_confirm_order(is_confirm);
    }
    if (is_confirm!=1){this.forceUpdate();}
    
    //let stateall=this.state;
  }
  sel_frame2(prop) {
    var cf_id=prop.cf_id; 
    var is_confirm=prop.is_confirm||0;
    var pframelvl=prop.pframelvl||2;
    this.state.priorFrame=pframelvl;
    this.state.CommandPanel.OptionSelection=[];//reset options
    var scf_id = this.state.CommandPanel.HoverSelection.frame2;
    if (scf_id === cf_id) {//unselect if already selected
      this.state.CommandPanel.HoverSelection.frame2=[];
      this.request_confirm_order(0); //clear confirm if unselect
    }else{
      this.state.currentFrame=3; //set frame state

      //auto expand all menu groups
      var menuGroupsNew=[];
      var PanelSelections=this.state.CommandPanel.PanelSelections;
      PanelSelections.forEach(tasks=>{
      tasks.PanelSelections.forEach(supp=>{supp.PanelSelections.forEach(mitem=>{
      if(supp.is_menu_expandall && menuGroupsNew.indexOf(mitem.menu_category_name)==-1){
      menuGroupsNew.push(mitem.menu_category_name)}})})});
      this.state.expandedOptions.menuGroups=menuGroupsNew;
      

      //get frame1 name
      var frameText="";
      var PanelSelections=this.state.CommandPanel.PanelSelections;
      for (var i=0;i<PanelSelections.length;i++){
        var PanelSelections2=PanelSelections[i].PanelSelections;
        for (var ii=0;ii<PanelSelections2.length;ii++){            
          if(PanelSelections2[ii].cf_id==cf_id){frameText=PanelSelections2[ii].Name;}
        }
      }
      this.state.frameText.frame3=frameText;

      this.request_confirm_order(is_confirm);
      this.state.CommandPanel.HoverSelection.frame2=[cf_id];
    }
    this.forceUpdate();
  }
  clear_selection() {
    this.state.CommandPanel.OptionSelection=[];//reset options
    this.forceUpdate();
  }
  clear_frame(prop){
    var framelvl=prop.framelvl;
    this.state.currentFrame=framelvl;
    if (framelvl==1){
      var cf_id=this.state.CommandPanel.HoverSelection.frame1;
      this.sel_frame1(cf_id, 0);
    }
    if (framelvl==2){
      var cf_id=this.state.CommandPanel.HoverSelection.frame2;
      this.sel_frame2({cf_id});
    }
    if (framelvl==3){this.clear_selection();}
    var pframelvl=prop.pframelvl||(framelvl-1);
    this.state.priorFrame=pframelvl;
  }
  clear_frame_group(group_id, item_num){
    let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
    for (var i=0;i<OptionSelection.length;i++){
    if(OptionSelection[i].item_num==item_num){
      var add_ons=OptionSelection[i].add_ons;
      var add_on_obj=[];
      for (var ii=0;ii<add_ons.length;ii++){ //add all addins  back not part of group
      if (group_id!=add_ons[ii].group_id){add_on_obj.push(add_ons[ii]);}}
      this.state.CommandPanel.OptionSelection[i].add_ons=add_on_obj; 
    }}
    this.forceUpdate();
  }
  add_menu_qty(option_obj,default_sel){
    var cf_id=option_obj.cf_id;
    var cf_price=option_obj.Price;
    var cf_name=option_obj.Name;
    var tasks=this.state.CommandPanel.PanelSelections;

    //default values
    var qty_added=1; var addons=[]; var is_maxrequired_full=0;
    if(default_sel.qty!=undefined){
      qty_added=default_sel.qty;
      if(default_sel.addons!=undefined){
        for(var i_addons=0;i_addons<default_sel.addons.length;i_addons++){
        var default_addon_cf_id=default_sel.addons[i_addons].cf_id;
        var default_addon_qty=default_sel.addons[i_addons].qty;
        
        //find other default value properties
        for (var i=0;i<tasks.length;i++){ var suppliers=tasks[i].PanelSelections;
        for (var ii=0;ii<suppliers.length;ii++){ var menuitems=suppliers[ii].PanelSelections;
        for (var j=0;j<menuitems.length;j++){ var groups=menuitems[j].PanelSelections;
        if(menuitems[j].cf_id==cf_id){ if (groups!=undefined){ 
        for (var jj=0;jj<groups.length;jj++){ 
          var group_addons=groups[jj].PanelSelections||[];
          for (var k=0;k<group_addons.length;k++){ 
          if(group_addons[k].cf_id==default_addon_cf_id){
            var addon_cf_name=group_addons[k].Name;
            var addon_cf_price=group_addons[k].Price;
            var group_id=group_addons[k].ft_msub_id;
            var mitem_id=group_addons[k].ft_sub_id;
            var group_name=group_addons[k].ft_msub_name;
            if(groups[jj].max_qty_required>0 
            && groups[jj].max_qty==default_addon_qty){is_maxrequired_full++;}
          }
        }}}}}}}
        
        addons.push({
          "cf_id":default_addon_cf_id,
          "cf_name":addon_cf_name,
          "cf_price":addon_cf_price,
          "mitem_id":mitem_id,
          "group_id":group_id,
          "group_name":group_name,
          "qty":default_addon_qty
        });
      }}
    }

    //get max item num
    let OptionSelection=this.state.CommandPanel.OptionSelection;
    var item_num=1;
    for(var i=0;i<OptionSelection.length;i++){
    if(OptionSelection[i].item_num>=item_num){
      item_num=OptionSelection[i].item_num+1;
    }}
    //var item_num=OptionSelection.length+qty_added;
    var consumer_name=""; //retreive later

    //always push new
    OptionSelection.push({
      "cf_id":cf_id,
      "cf_name":cf_name,
      "cf_price":cf_price,
      "item_num": item_num,
      "mitem_id": option_obj.ft_sub_id,
      "add_ons": addons,
      "consumer_name": consumer_name,
      "is_valid":1
    });    
    this.state.CommandPanel.OptionSelection=OptionSelection;
    //display confirm only if there are options selected
    if (OptionSelection.length>0){ this.request_confirm_order(1);}

    //go to frame 4 if there are mandatory fields to select, and not selected by default selection
    //ALWAYS GO TO FRAME 4
    //TBC - add 'notes' section to each order
    var num_maxrequired=this.get_num_maxrequired(cf_id).num_maxrequired;
    if (is_maxrequired_full<num_maxrequired || 1==1){
      //select if navigating to Order frame
      this.state.CommandPanel.OptionSelectionSel=item_num;
      this.state.currentFrame=4;
    }
    this.forceUpdate();
  }
  get_num_maxrequired(menuitem_cf_id){
    var tasks=this.state.CommandPanel.PanelSelections;
    var num_maxrequired=0; var group_arr=[];
    for (var i=0;i<tasks.length;i++){ var suppliers=tasks[i].PanelSelections;
    for (var ii=0;ii<suppliers.length;ii++){ var menuitems=suppliers[ii].PanelSelections;
    for (var j=0;j<menuitems.length;j++){ if(menuitems[j].cf_id==menuitem_cf_id){
    var groups=menuitems[j].PanelSelections;
    if (groups!=undefined){ for (var jj=0;jj<groups.length;jj++){ 
      if(groups[jj].max_qty_required>0){
        num_maxrequired++;
        group_arr.push({
          "group_id":groups[jj].ft_sub_id,
          "group_name":groups[jj].Name,
          "max_qty":groups[jj].max_qty_required
        });
    }}}}}}}
    return {
      "num_maxrequired":num_maxrequired,
      "groups":group_arr,
    };
  }
  get_maxrequired_fulfilled(item_num_sel){
      var unfilled_msg="";
      let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
      for (var i=0;i<OptionSelection.length;i++){
        var cf_id=OptionSelection[i].cf_id;
        var item_num=OptionSelection[i].item_num;
        if(item_num==item_num_sel){
        var add_ons=OptionSelection[i].add_ons;
        //find groups with max_requirements
        var group_arr=this.get_num_maxrequired(cf_id).groups;
        for(var ii=0;ii<group_arr.length;ii++){
          var group_id=group_arr[ii].group_id;
          var group_name=group_arr[ii].group_name;
          var max_qty=group_arr[ii].max_qty;
          //find Group qty
          var gcur_qty=0; for (var j=0;j<add_ons.length;j++){
          if (group_id==add_ons[j].group_id){gcur_qty+=add_ons[j].qty;}}
          if (gcur_qty!=max_qty){
            var var_s="";if (max_qty>1){var_s="s";}
            unfilled_msg+=max_qty+" selection"+var_s+" required for "+group_name+". ";
          }   
        }}
      }
      return unfilled_msg;
  }
  add_menu_sel(item_num, groupsel_obj){
    var qtyadd=1;
    var cf_id=groupsel_obj.cf_id;
    var cf_name=groupsel_obj.Name;
    var cf_price=groupsel_obj.Price;
    var mitem_id=groupsel_obj.ft_sub_id;
    var group_id=groupsel_obj.ft_msub_id;
    var group_name=groupsel_obj.ft_msub_name;
    var max_qty=groupsel_obj.max_qty;
    var mmax_qty=groupsel_obj.mmax_qty;

    let OptionSelection=this.state.CommandPanel.OptionSelection; //state to POST
    for (var i=0;i<OptionSelection.length;i++){
    if(OptionSelection[i].item_num==item_num){

      var add_ons=OptionSelection[i].add_ons;
      var is_exists=0;

      //find Group qty
      var gcur_qty=0; 
      for (var ii=0;ii<add_ons.length;ii++){
      if (group_id==add_ons[ii].group_id){gcur_qty+=add_ons[ii].qty;}}
      var gnew_qty=gcur_qty+qtyadd;

      //find position in add_ons, find cur pty
      var add_on_curn; var cur_qty=0;
      for (var ii=0;ii<add_ons.length;ii++){
      if(add_ons[ii].cf_id==cf_id){ 
        is_exists=1; add_on_curn=ii;
        cur_qty=add_ons[ii].qty; ii=add_ons.length; //exit For
      }}
      var new_qty=cur_qty+qtyadd;
      if (new_qty<0){new_qty=0;}

      //0=do nothing, 1=unselect, remove qty, 2=remove others select, 3=add qty
      var is_unsel_all=0;
      var is_push_new=0;
      var is_update_cur=0;

      if(mmax_qty==1){ //single selections
          if (cur_qty==1){  is_unsel_all=1; } //if already selected unselect,
          else{ is_unsel_all=1; is_push_new=1; } //if not selected, select this and remove others
      }else{ //variable selection
          is_update_cur=1;
          //criteria to invalidate
          if (mmax_qty!=null && mmax_qty!=0){ if (gnew_qty>mmax_qty){ is_update_cur=0;}} //group criteria
          if (max_qty!=null && max_qty!=0){ if (new_qty>max_qty){ is_update_cur=0;}} //addon criteria
      } 

      if (is_update_cur==1){ //Update Current - may trigger below
        if (is_exists==1){
          if(new_qty==0){ //zero - remove current item in group
            var add_on_obj=[];
            for (var ii=0;ii<add_ons.length;ii++){ //add all addins  back not current cf_id
            if (cf_id!=add_ons[ii].cf_id){add_on_obj.push(add_ons[ii]);}}
            this.state.CommandPanel.OptionSelection[i].add_ons=add_on_obj; 
          }else{ 
            this.state.CommandPanel.OptionSelection[i].add_ons[add_on_curn].qty=new_qty;
          }
        }else if(new_qty>0){is_push_new=1;}
      }       

      if (is_unsel_all==1){ //remove other addons in group
        var add_on_obj=[];
        for (var ii=0;ii<add_ons.length;ii++){ //add all addins  back not part of group
          if (group_id!=add_ons[ii].group_id){add_on_obj.push(add_ons[ii]);}}
        this.state.CommandPanel.OptionSelection[i].add_ons=add_on_obj; 
      } 
      
      if (is_push_new==1){ //new selection
        var addon_obj={
          "cf_id":cf_id,
          "cf_name":cf_name,
          "cf_price":cf_price,
          "mitem_id":mitem_id,
          "group_id":group_id,
          "group_name":group_name,
          "qty":new_qty
        };
        this.state.CommandPanel.OptionSelection[i].add_ons.push(addon_obj);
      }

      //exit For
    }}
    this.forceUpdate();
  }
  sel_order(item_num){
    var item_num_sel=this.state.CommandPanel.OptionSelectionSel;
    if(item_num_sel==item_num){ 
      //done with edit - check if compulsary added
      var error_msg=this.get_maxrequired_fulfilled(item_num);
      if(error_msg!=""){alert(error_msg);}else{
      this.state.CommandPanel.OptionSelectionSel=0;}
    } else{this.state.CommandPanel.OptionSelectionSel=item_num;}
    this.forceUpdate();
  }
  sel_order_del(item_num){
    
    //reassign all but deleted
    let OptionSelection=this.state.CommandPanel.OptionSelection;
    var OptionSelection_arr=[];
    for (var i=0;i<OptionSelection.length;i++){
    if (item_num!=OptionSelection[i].item_num){
    OptionSelection_arr.push(OptionSelection[i]);}}
    this.state.CommandPanel.OptionSelection=OptionSelection_arr;
    //set selection = 0 
    this.state.CommandPanel.OptionSelectionSel=0;
    this.forceUpdate();
  }
  request_confirm_order(is_confirm){
    //add reset button to buttons
    this.state.confirm_obj=[];
    var confirm_obj = [];
    var confirm_obj2 = [];

    confirm_obj2.push({
        "text":"Reset Order",
        "class":"",
        "onclick_func":this.clear_selection
    });
    if (is_confirm===1){
      confirm_obj.push({
          "text":"Confirm Request",
          "class":"td_confirm",
          "onclick_func":this.test_func
      });
    }else{
      confirm_obj.push({
        "text":"Confirm Request",
        "class":"td_confirm_inactive",
        "onclick_func":""
      });
    }
    //this.state.confirm_obj=confirm_obj;
    this.forceUpdate();

  }
  renderFrame(i){
    var div_frame;
    if (i==1){div_frame=this.renderFrame1();}if (i==2){div_frame=this.renderFrame2();}
    if (i==3){div_frame=this.renderFrame3();}if (i==4){div_frame=this.renderFrame4();}
    return (div_frame);
  }
  render() {
    var frames=[];
    if (this.state.CommandPanel.ModelType=="Initial Model"){
      frames.push(<div>Tom is waking up, please wait... If I'm still sleeping after 5 seconds, Feel free to refresh the page.</div>);
    }else{
    //no frame 4 if no order
    let OptionSelection=this.state.CommandPanel.OptionSelection;
    if(OptionSelection.length==0 
    && this.state.currentFrame==4){
      this.state.currentFrame=3;
    }
    for (var i=1;i<=4; i++){
      var div_class=" div_frame_td"+i+" ";      
      if(i!=this.state.currentFrame){div_class+=" td_tablet_hide ";}
      var dframe=this.renderFrame(i);
      frames.push(<div key={div_class} className={div_class}>{dframe}</div>);  
    }}
    return (
      <div className="CommandPanel">   
         {frames}
      </div>
    );
  }
}