/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import "./UserDetails.css";
import { API } from "aws-amplify";
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";
import htmlInputs from '../../../func/htmlInputs.js'; 

const UserDetailsModel = {
  source:"Preload values",
  data:
  [
    {field_id:"firstName",field_value:"Preload"},
    {field_id:"lastName",field_value:"Preload"},
    {field_id:"email",field_value:"Preload"},
    {field_id:"mobile",field_value:"Preload"}
  ]
}

const inputModel=[
  {"field_name":"First Name:","field_id":"firstName"},
  {"field_name":"Last Name:","field_id":"lastName"},
  {"field_name":"Email:","field_id":"email"},
  {"field_name":"Mobile:","field_id":"mobile"},
  ];

export default class UserDetails extends Component {

  //add inputrefDOM into Model
  inputModel=[
    {"field_name":"First Name:","field_id":"firstName"},
    {"field_name":"Last Name:","field_id":"lastName"},
    {"field_name":"Email:","field_id":"email"},
    {"field_name":"Mobile:","field_id":"mobile"},
  ];

  constructor(props) {
    super(props);
    //handle DOM variables
    for (var i=0; i<inputModel.length; i++){
      //create ref
      this[inputModel[i]["field_id"]] = React.createRef(); 
      //bind inputRef for class to model
      inputModel[i].inputRef=this[inputModel[i]["field_id"]];
    }
    this.state = {
      isLoading: true,
      action: "view",
      UserDetails : UserDetailsModel
    };
  }
  async componentDidMount() {
    this.getUserDetails()
    .then((data) => {
      this.setState({UserDetails : data});
    })
    .catch((err) => {
      console.log(err);
    });
    this.setState({ isLoading: false });    
  }
  getUserDetails = async event => {
    //return await API.get('dev-profile-service', '/UserDetails');
    return await API.get('v1', '/userdetails');
  }
  editDetails_exe = async event => {

    var post_arr={}; var field_value; var field_id;
    post_arr["action"]=this.state.action;
    for (var i=0; i<inputModel.length; i++){
      field_value=this[inputModel[i]["field_id"]].current.value;
      field_id=inputModel[i]["field_id"];
      post_arr[field_id]=field_value;
    }
    return await API.post('v1', '/userdetails',
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      this.getUserDetails()
      .then((data) => {
        this.setState({
          action:"view",
          UserDetails : data
        });
      })
      .catch((err) => {
        console.log(err);
      });

    });    
  }

  render() {
    var userDetails=htmlInputs.input_rows({
      model:this.state.UserDetails,
      action:this.state.action,
      inputModel:inputModel,
      buttons_footer:[
        {
          action:"view",
          displayName:"Edit",
          class:"myButton_edit myButton",
          func: (() => this.setState({action:"edit"})),
        },
        {
          action:"edit",
          displayName:"Save",
          class:"myButton_edit myButton",
          func:(() => this.editDetails_exe())
        },
        {
          action:"edit",
          displayName:"Cancel",
          class:"myButton_cancel myButton",
          func:(() => this.setState({action:"view"})),
        },
      ]
    });


    return (
      <div>
        <div>
          <h3>Your details</h3>
        </div>
        <div>{userDetails}</div>
      </div>
    );
  }
}