import React, { Component } from "react";
import "./Dashboard.css";

export default class Dashboard extends Component {
  render() {
    return (
      <div className="Dashboard">
         <h1>Robot Hub Portal</h1>
      </div>
    );
  }
}