/* eslint {eqeqeq:0,no-redeclare:0,no-unused-vars:0} */
import React, { Component } from "react";
import Routes from "./Routes";
import { Nav, Navbar, NavItem } from "react-bootstrap";

//import Amplify,{Auth, Hub} from 'aws-amplify';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import { withOAuth } from 'aws-amplify-react';
import "./App.css";
import AuthNavItems from "./components/AuthNavItems.js";
import configFile from "./config";
import { API } from "aws-amplify";

// your Cognito Hosted UI configuration
//const 
var ampconfiguration={
  Auth: {
    mandatorySignIn: true,
    region: configFile.awsConfig.cognito.REGION,
    userPoolId: configFile.awsConfig.cognito.USER_POOL_ID,
    //identityPoolId: configFile.awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: configFile.awsConfig.cognito.APP_CLIENT_ID,
    oauth: configFile.awsConfig.oauth
  },
  Storage: {
    region: configFile.awsConfig.s3.REGION,
    bucket: configFile.awsConfig.s3.BUCKET,
    identityPoolId: configFile.awsConfig.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: configFile.awsConfig.API.endpoints
  }
};

console.log("ampconfiguration");console.log(ampconfiguration);
Amplify.configure(ampconfiguration);

console.log("app34");
try{
  //Auth.configure(oauth);
  if(configFile){
    console.log("isdefined")
    console.log("app38");
    Auth.configure( configFile.awsConfig.oauth );
    console.log("app40");
  }else{
    console.log("wait for some time");
  }
} catch (err){
  console.log("reference not ready");
}

class App extends Component {
  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.signOut = this.signOut.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', this);
    this.state = {
      userName: '',
      userDetails : '',
      authState: 'loading'
    }
    console.log("Build: " + configFile.version);
  }

  signOut() {
    Auth.signOut().then(() => {
      //this.setState({authState: 'signedOut'});
    }).catch(e => {
      console.log(e);
    });
  }

  UsersignIn() {    
    
    //signout guest account
    Auth.signOut().then(() => {
      this.setState({authState: 'signedOut'});

      //wait 3 seconds before going to User signin page 
      //console.log("waiting 3 seconds");
      //setInterval(async () => {
        //window.location.reload()

        console.log("Directing to Sign in page");
        //direct to signin page
        this.props.OAuthSignIn();

      //}, 3000);     


    }).catch(e => {
      console.log(e);
    });    
  }
  GuestsignIn(){    

    //check if there is already an active user signed in 

    var Guestusername="robothubguest";
    var Guestpassword="@f#4tsD2df";
    Auth.signIn({
      username:Guestusername, // Required, the username
      password:Guestpassword
    }).then(user => console.log(user))
    .catch(err => console.log(err));

  }

  getUserDetails = async event => {
    //return await API.get('profile-service', '/user');
    return await API.get('v1', '/user');
  }

  setUserInfo = event =>{
    this.getUserDetails()
    .then((userInfo) => {
      var authState = "signUp";
      if(userInfo !== ""){
        authState = "signedIn";
      }      
      this.setState({
        userDetails: userInfo,
        authState: authState
      });
    })
    .catch((err) => {
      this.setState({
        authState:"signIn"
      })
      console.log(err);
    });   
  }

  componentDidMount() {

    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      console.log("Signed in, assigning user INfo");
      console.log(user);
      this.setUserInfo();
    }).catch(e => { //not signed in
      console.log(e);
      this.setState({authState: 'signIn'});


      console.log("Not signed in, Running Guest account");
      //wait for another 2 seconds and check again
      //include guest signin on auth redirects
      var href=window.location.href;
      if (
        href.indexOf("#id_token")==-1
        && (href.indexOf("?code=")==-1)
        && (href.indexOf("&state")==-1)
      ){

        //wait 3 seconds to recheck
        //console.log("waiting 3 seconds to recheck auth");
        //setInterval(async () => {
          //window.location.reload()
            console.log("Running Guest Signin function");
            this.GuestsignIn();
          
        //}, 3000);     

      }else{

      }



    });
  }

  onHubCapsule(capsule) {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          if(this.state.authState !== "loading")
          {
            this.setState({authState:"loading"});
            this.setUserInfo();
          }
          
          break;
        case 'signIn_failure':
          this.setState({authState: 'signedOut'});          
          break;
        default:
          break;
      }
    }
  }

  render() {
    const userProps = {
      userDetails: this.state.userDetails,
      authState: this.state.authState
    };
    //if not signed in, Auto signin as guest    
    var customerNo=this.state.userDetails.customerNo;  
    var signin_user="";
    if(customerNo==10034){ //guest account
      signin_user=(<Nav pullRight><NavItem onClick={
        (() => this.UsersignIn())}>Sign in</NavItem></Nav>);
    }

    return (
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <table>
                <tbody>
                <tr>
                  <td className="td_logo">
                    <img src="img/tom.png" height="40" alt="Robot-Hub"/>
                  </td><td>
                    {/*<span className="subheader">Hello {this.state.userDetails.firstName || "there"}, I'm Tom.</span>*/}
                  </td>
                </tr>
                </tbody>
              </table>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
          
          
        {signin_user}  
        {this.state.authState === 'loading' && (<Nav pullRight><NavItem>loading...</NavItem></ Nav>)}

        {this.state.authState === 'signUp' && <Nav pullRight><NavItem onClick={this.signOut}>Sign out {this.props.userName}  </NavItem></Nav>}

        {this.state.authState === 'signIn' && <Nav pullRight><NavItem onClick={(() => this.GuestsignIn())}> Sign in As Guest</NavItem></Nav>}
        {this.state.authState === 'signIn' && <Nav pullRight><NavItem onClick={this.props.OAuthSignIn}> Sign in</NavItem></Nav>}

        {this.state.authState === 'signedIn' && <AuthNavItems parentMethod={this.signOut} userName={this.state.userName} user={this.state.userDetails} />}

        </Navbar.Collapse>
        </Navbar>
        <Routes userProps={userProps} />
      </div>
    );
  }
}
//https://robot-hub.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=4f1eofo7tqsf17bm57gunghkqv&redirect_uri=http://localhost:3000/&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin
export default withOAuth(App);