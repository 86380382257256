/* eslint {eqeqeq:0,no-redeclare:0,no-unused-vars:0} */
import React from "react";
import jsFunc from '../../../func/jsFunc.js'; 

const UserLocations={

  active_locations: function(prop){
    
    var userAddresses=prop.userAddresses;
    var activeAddress=[];
    for (var i=0;i<userAddresses.length;i++){
      var userAddress=userAddresses[i];
      if(
        userAddress["address_type"]=="Work" 
        || userAddress["address_type"]=="Home"
        || userAddress["address_type"]=="Study"
      ){
      var full_add="";var cm=", ";
      full_add+=userAddress["str_no"];  
      full_add+=" "+userAddress["str_name"];
      cm=", ";if(userAddress["str_name"]==""){cm="";}
      full_add+=cm+userAddress["suburb"]; 
      cm=", ";if(userAddress["suburb"]==""){cm="";}
      full_add+=cm+userAddress["city"]; 
      full_add+=" "+userAddress["postcode"]; 
      full_add+=", "+userAddress["country"];
      
      var suburb=userAddress["suburb"];
      var city=userAddress["city"];

      //images
      var img="";
      if(userAddress["suburb"]=="Wynyard Quarter"){
        img=(<div className='imgbox' style={{"marginRight":"10px"}}>
              <img alt="" className='center-fit' src='img/wynyard.png'/>
          </div>);
      }
      img=""; //disable picture

      //address type - not used
      var addtype=(<span className='activeloc_home'>{userAddress["address_type"]}</span>);

      activeAddress.push(
      <div className='activeloc_wrapper'>
        
        <p>
        <img style={{"marginRight":"10px"}} src="img/gps.png" height="30" alt="Robot-Hub"/>
        {suburb}, {city}</p>
        
      </div>);
      }
    }


    var dactiveAddress="";
    if(activeAddress.length>0){
      dactiveAddress=(<div>{activeAddress}</div>);
    }else{
      dactiveAddress=(<div>
        <span className='activeloc_header'>You have no configured active locations, this helps Tom find
        tasks relevant to you. Add this now to get customised Tasks.
        <a href="/userAddress"><button className='myButton_new td_floatright'>Add Locations</button></a> </span>
        </div>);
    }
    return dactiveAddress;
  }, 



}

export default UserLocations;