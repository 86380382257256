/* eslint-disable */
import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
//import { LinkContainer } from "react-router-bootstrap";
import "./AuthNavItems.css";


export default class AuthNavItems extends Component {

  constructor(props) {
    super(props);
    var account = JSON.parse(localStorage.getItem('account')) || { name :"" };
    const roles = account.roles || [];
    //console.log(JSON.stringify(props.user));
    var selroleid=0;
    if(props.user.organisations[0].accounts.length>0){
      selroleid=props.user.organisations[0].accounts[0].roles[0].roleid;
    }

    var urlvar=this.getUrlParameter(window.location.href);
    if (urlvar["r"]!==undefined){ selroleid=urlvar["r"];}
    
    var selroleName=this.getRoleName({
      model:props.user,
      roleid:selroleid
    });

    this.state = {
      userDetails: props.user,
      selectedAccount: account.name, 
      roles : roles,
      isLoading: true,
      selectedRoleId : selroleid,
      selectedRoleName : selroleName   
    };
    //console.log(JSON.stringify(this.state));
  }

  getRoleName(prop){
    var organisations=prop.model.organisations;
    var roleid=prop.roleid;
    //console.log(JSON.stringify(prop));
    var selroleName="";
    for (var i=0; i<organisations.length;i++){ let accounts=organisations[i]["accounts"];
    for (var ii=0; ii<accounts.length;ii++){ let roles=accounts[ii]["roles"];
    for (var iii=0; iii<roles.length;iii++){ if (roleid==roles[iii].roleid){
        var orgname=accounts[ii].name;
        var rolename=roles[iii].name;
        selroleName=orgname+" - "+rolename;
        if(orgname=="Robot-Hub Consumers"){selroleName=rolename;}
    }}}}

    return selroleName;

  }

  onAccountSelect(e) {

    var account = this.state.userDetails.organisations.map(
      o => o.accounts.filter(a => a.name === this.accountInput.value))[0][0];
    localStorage.setItem('account', JSON.stringify(account));
    this.setState({
      selectedAccount: account.name,
      roles: account.roles
    });
  }
  RoleSelect(prop){
    var roleID=prop.roleID;
    var roleName=prop.roleName;
    this.setState({ 
      selectedRoleId: roleID,
      selectedRoleName: roleName
    });
  }
  onRoleSelect(e){
    //console.log(JSON.stringify(this.state));
    let organisations=this.state.userDetails.organisations;
    let role_sel={};
    for (var i=0; i<organisations.length;i++){ let accounts=organisations[i]["accounts"];
    for (var ii=0; ii<accounts.length;ii++){ let roles=accounts[ii]["roles"];
    for (var iii=0; iii<roles.length;iii++){
          if (this.roleInput.value===roles[iii].roleId){role_sel=roles[iii];
    }}}}
    localStorage.setItem( 'role', JSON.stringify(role_sel) );
    this.setState({ 
      selectedRoleId: this.roleInput.value
    });
  }
  signOut = () => {
    this.props.parentMethod();
  }
  renderRoles() {
    //return;
    //console.log(this.state);
    const orgs  = this.state.userDetails.organisations;
    return orgs.map((org, i) =>
      org.accounts.map((account, j) =>
      account.roles.map((role, k) =>
        <option key={(i+1)*(j+1)*(k+1)} value={role.roleid}>{
          (account.name!=="Robot-Hub Consumers")?account.name+" - ":""
        }{role.name}</option>
    )));
  }
  renderRolesNew() {
    //return;
    //console.log(this.state);
    const orgs  = this.state.userDetails.organisations;
    var menuItems=[];
    
    orgs.map((org, i) =>
      org.accounts.map((account, j) =>
      account.roles.map((role, k) =>
      menuItems.push(this.renderRole({
        key:i,
        roleid:role.roleid,
        mroleid:role.mroleid,
        rolename:role.name,
        accountname:account.name
      }))      
    )));
    
    var title=this.state.selectedRoleName;

    return (
      <NavDropdown key={11} title={title} id="basic-nav-dropdown">
      {menuItems}
      </NavDropdown>
    );
  }
  renderRole(prop) {
    var FullRole=prop.accountname+" - "+prop.rolename;
    if(prop.accountname=="Robot-Hub Consumers"){FullRole=prop.rolename;}
    var href="/commandpanel";
    if(prop.mroleid==2){href="/incoming?r="+prop.roleid;}
    if(prop.roleid==11){href="/fintrans?r="+prop.roleid;}
    var onclick=(() => this.RoleSelect({
      roleID:prop.roleid,
      roleName:FullRole
    }));
    return(<MenuItem  href={href}      
      eventKey={prop.key}>{FullRole}</MenuItem>);
  }
  renderAdmin(roleid) {
    return (
      <NavDropdown key={3} title="Admin" id="basic-nav-dropdown">
        <MenuItem href={"/settingsconfig?r="+roleid} eventKey={3.1}>Configure Settings</MenuItem>
        <MenuItem divider />
        <MenuItem href={"/settings?r="+roleid+"&s="+4} eventKey={12.2}>Ordering Settings</MenuItem>
        
      </NavDropdown>
    );
  }

  renderOrgAdmin() {
    return (
      <NavDropdown key={3} title="Admin" id="basic-nav-dropdown">
        <MenuItem eventKey={3.1}>Administer Organisation</MenuItem>
        <MenuItem eventKey={3.2}>Organisation Users</MenuItem>
        <MenuItem eventKey={3.3}>Customer Roles</MenuItem>
        <MenuItem divider />
        <MenuItem eventKey={3.4}>Robot Trainers</MenuItem>
      </NavDropdown>
    );
  }
  renderTrainer() {
    return (
      <NavDropdown key={4} title="Trainer" id="basic-nav-dropdown">
        <MenuItem eventKey={4.1}>Create Task</MenuItem>
        <MenuItem href="/trainertasks" eventKey={3.2}>View All Tasks</MenuItem>
        <MenuItem eventKey={4.3}>Test Tasks</MenuItem>
        <MenuItem divider />
        <MenuItem eventKey={4.4}>Your Dashboard</MenuItem>
      </NavDropdown>
    );
  }

  renderSupplierStore(roleid) {
    return (
      <NavDropdown key={5} title="Store" id="basic-nav-dropdown">
        
        <MenuItem href={"/supplierstores?r="+roleid} eventKey={5.3}>Your Stores</MenuItem>
        <MenuItem href={"/supplierreach?r="+roleid} eventKey={5.4}>Customer Reach</MenuItem>
        <MenuItem href={"/supplierhours?r="+roleid} eventKey={5.5}>Opening Hours</MenuItem>
        <MenuItem href={"/settings?r="+roleid+"&s="+2} eventKey={5.6}>Store Ledgers</MenuItem>
        <MenuItem divider />
        <MenuItem href={"/suppliermenuitems?r="+roleid} eventKey={5.7}>Menu Items</MenuItem>
        <MenuItem href={"/suppliermenugroups?r="+roleid} eventKey={5.8}>Addon Groups</MenuItem>
        <MenuItem href={"/supplieraddons?r="+roleid} eventKey={5.9}>Addon Items</MenuItem>
        <MenuItem divider />
        <MenuItem href={"/suppliermenustock?r="+roleid} eventKey={5.10}>Stock levels</MenuItem>
        <MenuItem divider />
        <MenuItem href={"/supplierorders?r="+roleid} eventKey={5.21}>Customer Orders</MenuItem>
        <MenuItem href={"/loyalty?r="+roleid} eventKey={5.22}>Loyalty Balances</MenuItem>
        <MenuItem href={"/reach?r="+roleid} eventKey={5.23}>Customer Reach</MenuItem>
        <MenuItem href="/promotion" eventKey={5.24}>Run Promotion</MenuItem>
        <MenuItem divider />
        
      </NavDropdown>
    );
  }
  renderOrgFinance(roleid) {
    return (
      <NavDropdown key={6} title="Finance" id="basic-nav-dropdown">
        <MenuItem href={"/ledgers?r="+roleid} eventKey={6.1}>Ledgers</MenuItem>
        <MenuItem href={"/creditorinv?r="+roleid} eventKey={6.2}>Creditor Invoices</MenuItem>
        <MenuItem href={"/debtorinv?r="+roleid} eventKey={6.3}>Debtor Invoices</MenuItem>
        <MenuItem href={"/settings?r="+roleid+"&s="+3} eventKey={6.10}>Organisation Setup</MenuItem>
      </NavDropdown>
    );
  }
  renderFinanceAdmin(roleid) { //not used
    return (
      <NavDropdown key={12} title="Finance" id="basic-nav-dropdown">
        <MenuItem href={"/ledgers?r="+roleid} eventKey={12.1}>Ledgers</MenuItem>
        <MenuItem divider />
        
      </NavDropdown>
      );
  }  

  renderOperationsAdmin(roleid) {
    return (
      <NavDropdown key={13} title="Merchants" id="basic-nav-dropdown">
        <MenuItem href={"/kiosks?r="+roleid} eventKey={13.1}>Kiosk Status</MenuItem>
        <MenuItem divider />
        <MenuItem href={"/supplierstores?r="+roleid} eventKey={13.3}>Universal Stores</MenuItem>
        <MenuItem href={"/suppliermenuitems?r="+roleid} eventKey={13.7}>Menu Items (Universal)</MenuItem>
        <MenuItem href={"/suppliermenugroups?r="+roleid} eventKey={13.8}>Addon Groups (Universal)</MenuItem>
        <MenuItem href={"/supplieraddons?r="+roleid} eventKey={13.9}>Addon Items (Universal)</MenuItem>
        <MenuItem divider />
        <MenuItem href={"/fintrans?r="+roleid} eventKey={13.10}>Transactions</MenuItem>
        <MenuItem href={"/finsettle?r="+roleid} eventKey={13.11}>Settlements</MenuItem>

      </NavDropdown>
    );
  }  
  renderUser() {
    var userDetails=this.state.userDetails; 
    
    var fullName=userDetails.firstName+" "+userDetails.lastName+" (#"+userDetails.customerNo+")";
    //var dsignin="Signed in as "+fullName;
    var dsignin=fullName;
    return (
      <NavDropdown key={9} title={dsignin} id="basic-nav-dropdown">
        <MenuItem eventKey={9.1} href="/userDetails">Personal Details</MenuItem>
        <MenuItem divider/>
        <MenuItem eventKey={9.6} onClick={this.signOut}>Sign out {this.props.userName}</MenuItem>
        
      </NavDropdown>
    );
  }

  renderCustom(role_obj) {
    return (
      <NavDropdown key={7} title={role_obj} id="basic-nav-dropdown">
        <MenuItem eventKey={7.1}>Custom Action1</MenuItem>
        <MenuItem eventKey={7.2}>Custom Action2</MenuItem>
        <MenuItem eventKey={7.3}>Something else here</MenuItem>
        <MenuItem divider />
        <MenuItem eventKey={7.4}>Custom Action4</MenuItem>
      </NavDropdown>
    );
  }

  getUrlParameter(url) {
    var var_obj={};
    if(url.indexOf("?")>-1){
      var url_arr=url.split("?"); var var_arr=url_arr[1].split("&");
      var obj_single={};
      for (var i=0;i<var_arr.length;i++){
        obj_single=var_arr[i].split("="); var_obj[obj_single[0]]=obj_single[1];
      }
    }
    return(var_obj);
  }
  render() {


    let allnavitems=[];
    let roleid =this.state.selectedRoleId;
    let organisations=this.state.userDetails.organisations;  
    var role; var mroleid; 
    for (var i=0; i<organisations.length;i++){
      let accounts=organisations[i]["accounts"];
      for (var ii=0; ii<accounts.length;ii++){
        let roles=accounts[ii]["roles"];
        for (var iii=0; iii<roles.length;iii++){
          if (roleid==roles[iii]["roleid"]){
            role=roles[iii]["name"];
            mroleid=roles[iii]["mroleid"];
          }
        }
      }
    }

    if(this.state.userDetails.customerNo==10034){ //guest account
      allnavitems.push(<NavItem key={10.1} href="/commandpanel" >Create Order</NavItem>); //Render Home 
      allnavitems.push(<NavItem key={10.4} href="/questions" >FAQs</NavItem>); //Render Home 
    }else{
      if (roleid==1){
        allnavitems.push(<NavItem key={10.1} href="/commandpanel" >Create Order</NavItem>); //Render Home 
        allnavitems.push(<NavItem key={10.2} href="/orders" >Your Orders</NavItem>); //Render Home 
        allnavitems.push(<NavItem key={10.3} href="/userAddress" >Active Locations</NavItem>); //Render Home 
        allnavitems.push(<NavItem key={10.4} href="/questions" >FAQs</NavItem>); //Render Home 
      }
      if (mroleid==2){ //managing director
        var href="/incoming?r="+roleid;
        allnavitems.push(<NavItem key={10.1} href={href} >Incoming Orders</NavItem>); //Render Home 
        allnavitems.push(this.renderSupplierStore(roleid)); 
        allnavitems.push(this.renderOrgFinance(roleid)); 
      }
      if (roleid==11){ //RH Admin
        allnavitems.push(this.renderOrgFinance(roleid)); 
        allnavitems.push(this.renderAdmin(roleid)); //Render user details
        allnavitems.push(this.renderOperationsAdmin(roleid)); //Render user details
      }    
      allnavitems.push(this.renderUser()); //Render user details
      allnavitems.push(this.renderRolesNew()); //Render user Roles
    }
      
    return (      
      <Nav pullRight>
        {allnavitems}        
      </Nav>
      
    );
  }
}