/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import "./UserAddress.css";
import { API } from "aws-amplify";
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";


const UserAddressModel = {
  cognito_id : "testcognito",
  saved_address : [
    {
      "add_id":1,
      "address_type":"work",
      "str_no":"185",
      "str_name":"Fanshawe123",
      "str_type":"Street",
      "suburb":"Auckland CBD",
      "city":"Auckland",
      "postcode":"1010",
      "country":"New Zealand",
      "is_default":"1"
    },
    {
      "add_id":2,
      "address_type":"home",
      "str_no":"157",
      "str_name":"Tiriwa123",
      "str_type":"Drive",
      "suburb":"Massey",
      "city":"Auckland",
      "postcode":"0614",
      "country":"New Zealand",
      "is_default":"1"
    }
  ]
}
const inputModel=[
  {"field_name":"Street Number:","field_id":"str_no"},
  {"field_name":"Street Name:","field_id":"str_name"},
  {"field_name":"Suburb:","field_id":"suburb"},
  {"field_name":"Postcode:","field_id":"postcode"},
  {"field_name":"City:","field_id":"city"},
  {"field_name":"Country","field_id":"country"},
  {"field_name":"Address Type","field_id":"address_type"},
  {"field_name":"Active?","field_id":"is_default"}
];

export default class UserAddress extends Component {

  constructor(props) {
    super(props);
    for (var i=0; i<inputModel.length; i++){
    this[inputModel[i]["field_id"]] = React.createRef();
    }
    this["str_name"]=React.createRef();
    this.state = {
      isLoading: true,
      add_id_sel: -1,
      new_add_type: "",
      UserAddress : UserAddressModel
    };
  }
  async componentDidMount() {
    this.getUserAddress()
      .then((data) => {
        //this.state.UserAddress=data;
        this.setState({
          UserAddress : data
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ isLoading: false });    
  }
  getUserAddress = async event => {
    //return await API.get('dev-profile-service', '/useraddress');
    return await API.get('v1', '/useraddress');
  }
  edit_address_exe = async event => {

    /*
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {var str_name = node.querySelector('#str_name');}
    alert(str_name.value);
    */
    //alert(this.str_name.current.value);
    var post_arr={}; var field_value; var field_id;
    post_arr["add_id"]=this.state.add_id_sel;
    //post_arr["add_type"]=this.state.new_add_type;
    for (var i=0; i<inputModel.length; i++){
      field_value=this[inputModel[i]["field_id"]].current.value;
      field_id=inputModel[i]["field_id"];
      post_arr[field_id]=field_value;
    }
    return await API.post('v1', '/useraddress',
    {
      'Content-Type': 'application/json',
      body: post_arr
    })
    .then(result => {
      console.log(JSON.stringify(result));
      //alert(JSON.stringify(result));

      //retreive user address again
      this.getUserAddress()
      .then((data) => {
        this.setState({
          UserAddress : data
        });
      })
      .catch((err) => {
        console.log(err);
      });

      this.state.add_id_sel=-1;
      this.state.new_add_type="";
      //this.forceUpdate();
    });
    
  }
  renderCognito(){
    return (this.state.UserAddress.cognito_id);
  }

  new_address(add_type){
    this.state.new_add_type=add_type;
    this.state.add_id_sel=0;
    this.forceUpdate();
  }
  edit_address(add_id){
    this.state.add_id_sel=add_id;
    this.forceUpdate();
  }
  edit_address_clear(){
    this.state.add_id_sel=-1;
    this.state.new_add_type="";
    this.forceUpdate();
  }

  renderAddress(add_type){
    var UserAddress=this.state.UserAddress.saved_address;
    let home_add_arr=[];let home_add_sel=[];
    var nrow=UserAddress.length; 
    if(this.state.add_id_sel===0 && add_type===this.state.new_add_type){nrow=1;}
    var addarr; var full_add;

    for (var i_add=0;i_add<nrow;i_add++){
      if (add_type===this.state.new_add_type){
        addarr=this.state.UserAddress.new_address;
        addarr["address_type"]["input_value"]=add_type;
      }else {addarr=UserAddress[i_add];}

      if(addarr["address_type"]["input_value"]===add_type){
        full_add="";
        full_add+=addarr["str_no"]["input_value"];  full_add+=" "+addarr["str_name"]["input_value"];
        //full_add+=" "+addarr["str_type"];
        full_add+=", "+addarr["suburb"]["input_value"]; full_add+=", "+addarr["city"]["input_value"]; 
        full_add+=" "+addarr["postcode"]["input_value"]; full_add+=", "+addarr["country"]["input_value"];

        const add_id=addarr["add_id"]["input_value"];
        var edit_button=(<span class="myButton_edit myButton" onClick={() => this.edit_address(add_id)} >Edit</span>);
        var default_ind=(<span className="default_set">(Set as default)</span>); 
        default_ind=""; //set via edit

        if(addarr["is_default"]["input_value"]===1){
          default_ind=(<span className="default_add">Active</span>);
        }

        if (add_id===this.state.add_id_sel){ //edit/new
          var edit_but=(<span class="myButton_edit myButton" onClick={() => this.edit_address_exe()}>Save</span>);
          var cancel_but=(<span class="myButton_cancel myButton" onClick={() => this.edit_address_clear()}>Cancel</span>);
          
          var dfield_arr=[];
          var inputbox;
          for (var i=0; i<inputModel.length;i++){
            
            var input_type="text";
            if(addarr[inputModel[i]["field_id"]]["input_prop"]!==undefined){
              input_type=addarr[inputModel[i]["field_id"]]["input_prop"]["type"];
            }

            if(input_type==="text" || input_type==="hidden"){
              inputbox=(
                <input 
                type={inputModel[i]["field_type"]} 
                id={inputModel[i]["field_id"]} 
                ref={this[inputModel[i]["field_id"]]}
                className="input_tb" 
                defaultValue={addarr[inputModel[i]["field_id"]]["input_value"]} />
              );
            }else if(input_type==="dropdown"){
              var dd_selection=[];
              var dd_arr=addarr[inputModel[i]["field_id"]]["input_prop"]["selection"];
              for(var i_dd=0; i_dd<dd_arr.length; i_dd++){
                dd_selection.push(
                  <option value={dd_arr[i_dd]["value"]}>{dd_arr[i_dd]["display"]}</option>
                );
              }
              inputbox=(
                <FormGroup>
                  <FormControl componentClass="select"
                  className="input_tb" 
                  id={inputModel[i]["field_id"]} 
                  inputRef={this[inputModel[i]["field_id"]]}
                  defaultValue={addarr[inputModel[i]["field_id"]]["input_value"]}>
                  {dd_selection}
                  </FormControl>
                </FormGroup>
              );
            }

            var div_class="";if(input_type==="hidden"){div_class="hidden";}
            dfield_arr.push(
              <div className={div_class}>
                <div className="div_fieldname">{inputModel[i]["field_name"]}</div>
                <div className="div_fieldinput">{inputbox}</div>
              </div>
            );
          }
          home_add_sel.push(<div className="div_lineoption">{dfield_arr}<div>{edit_but} {cancel_but}</div></div>);
        }else{//view
          home_add_arr.push(<div className="div_lineoption">{full_add} {edit_button} {default_ind}</div>);
        }
      }
    }
    var add_new=(<button className="myButton_new myButton" onClick={() => this.new_address(add_type)}>Add</button>);
    var dadd_type; 
    if (add_type==1){dadd_type="Home";}
    if (add_type==2){dadd_type="Work";}
    if (add_type==3){dadd_type="Study";}
    if (add_type==6){dadd_type="Commute Route";}
    if (add_type==7){dadd_type="Leisure Areas";}

    if(add_type===this.state.new_add_type){add_new="";}
    if (home_add_sel.length>0){home_add_arr=home_add_sel;add_new="";}
    return(<div>
      <div class="section_header">{dadd_type}</div>
      {home_add_arr} {add_new}
      </div>);
  }

  render() {
    return (
      <div>
        <div>
          Entering your addresses below helps Tom decide on functions relevant to you.
        </div>
        <div>{this.renderAddress(1)}</div>
        <div>{this.renderAddress(2)}</div>
        <div>{this.renderAddress(3)}</div>
        <div>{this.renderAddress(6)}</div>
        <div>{this.renderAddress(7)}</div>
      </div>
    );
  }
}