import React, { Component } from "react";
import "./SignUp.css";
import { Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import { API } from "aws-amplify";


export default class SignUp extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      firstName: '',
      lastName: '',
      signedUp: false,
    };
  }

  handleSubmit = async event => {
    event.preventDefault();

    API.post('profile-service', '/signup', {
      body: this.state
    })
      .then(response => {
        this.setState({ signedUp: true });
      }).catch(error => {
        console.log(error.response)
      });

    event.preventDefault();

  }

  getValidationState() {
    const length = this.state.firstName.length;
    if (length > 10) return 'success';
    else if (length > 5) return 'warning';
    else if (length > 0) return 'error';
    return null;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderRedirect = () => {
    if (this.state.signedUp) {
      return <Redirect to='/commandPanel' />
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {this.renderRedirect()}
        <FormGroup
          controlId="formBasicText"
        >
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            name="firstName"
            type="text"
            value={this.state.firstName}
            placeholder="First Name"
            onChange={this.handleInputChange}
          />
          <FormControl.Feedback />
        </FormGroup>
        <FormGroup
          controlId="formBasicText"
        >
          <ControlLabel>Last Name</ControlLabel>
          <FormControl
            name="lastName"
            type="text"
            value={this.state.lastName}
            placeholder="Last Name"
            onChange={this.handleInputChange}
          />
          <FormControl.Feedback />

        </FormGroup>
        
        <Button type="submit" value="Submit" >
          Register
        </Button>
      </Form>
    );
  }
}

