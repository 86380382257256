/* eslint-disable */
import React, { Component } from "react";
import "./CustomerOrders.css";
import { API } from "aws-amplify";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import MenuOrders from '../../objects/orders/MenuOrders.js'; 
import jsFunc from '../../../func/jsFunc.js'; 

const CustomerOrdersModel = 
{
    "ModelType": "Temp Model",
    "Orders": []
}

export default class CustomerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orderIDExpanded : [],
      CustomerOrders : CustomerOrdersModel
    };    
  } 

  async componentDidMount() { 

    this.getCustomerOrders()
    .then((data) => {
      //set all progress orders to show
      this.setState({
        CustomerOrders: data
      });

      this.ExpandOption({
        action:"add",
        ExpandCategory:"Incomplete Orders",
        orders: data.Orders
      });
      this.ExpandOption({
        action:"add",
        ExpandCategory:"Orders In Progress",
        orders: data.Orders
      });
      this.ExpandOption({
        action:"add",
        ExpandCategory:"Completed",
        orders: data.Orders
      });
      
      this.forceUpdate();
    })
    .catch((err) => {
      console.log(err);
    });
  this.setState({ isLoading: false }); 

    //refresh 3 secs
    try {
      setInterval(async () => {

        this.getCustomerOrders()
        .then((data) => {
          //set all progress orders to show
          this.setState({
            CustomerOrders: data
          });
        })
        .catch((err) => {
          console.log(err);
        });  
      this.setState({ isLoading: false }); 

      }, 3000);
    } catch(e) {
      console.log(e);
    }


  }
  getCustomerOrders = async event => {
    var orderIDhash=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["o"]!==undefined){ orderIDhash=urlvar["o"];}   
    return await API.get('v1', '/customerorders?o='+orderIDhash);
  }
  
  //post_task_exe = async event => {
  async removeOrder_exec(prop) {    
    const orderID=prop.orderID;    
    return await API.post('v1', '/customerorders',
    {
      'Content-Type': 'application/json',
      body: {
        action: "remove",
        orderID: orderID
      }
    })
    .then(result => {
      console.log(JSON.stringify(result));
      //reload customer orders
      this.getCustomerOrders()
      .then((data) => {
        //set all progress orders to show
        this.setState({
          CustomerOrders: data
        });                
        this.forceUpdate();
      })
      .catch((err) => {
        console.log(err);
      });


    });    
  }
  async resubmitPayment_exec(prop) {    
    const orderID=prop.orderID;    
    return await API.post('v1', '/customerorders',
    {
      'Content-Type': 'application/json',
      body: {
        action: "resubmitpayment",
        orderID: orderID
      }
    })
    .then(result => {
      console.log(JSON.stringify(result));
      this.forceUpdate();
    });    
  }

  renderOrderList(prop){
    var orderIDExpanded=this.state.orderIDExpanded;
    let Orders=this.state.CustomerOrders.Orders;
    var status=prop.status;
    var order_list=[];
    for (var i=0;i<Orders.length;i++){
      const OrderID=Orders[i].OrderID;  
      const paymentId=Orders[i].paymentId;  
      if(Orders[i].order_stage==status){
        const orderButtons=[];
        if(status=="Incomplete Orders"){
          if(paymentId){
          orderButtons.push({
            name:"Pay",
            className:"myButton_blue",
            func:(() => window.location.href='/confirmpayment?p='+paymentId)
          });}
          orderButtons.push({
            name:"X",
            className:"myButton_del",
            func:(() => this.removeOrder_exec({orderID:OrderID}))
          });
        }

        order_list.push(MenuOrders.renderOrder({
          order:Orders[i],
          status:status,
          func_expand:{
            add:(() => this.ExpandOption({action:"add",orderID:OrderID})),
            remove:(() => this.ExpandOption({action:"remove",orderID:OrderID})),
          },
          orderButtons:orderButtons,
          orderIDExpanded:orderIDExpanded
        }));
      };
    }

    var className=" td_header orderlist_header ";
    if(status=="Completed"){
      //className+=this.state.blinkstate;
      //className+=" blink";
      className+=" orderlist_header_green ";
    }
    if(status=="Incomplete Orders"){
      className+=" orderlist_header_red ";
    }
    if(status=="Orders In Progress"){
      className+=" orderlist_header_border ";
    }


    if(order_list.length>0){
      return (
      <div >
        <div className={className}>{status}</div>
        {order_list}
      </div>
      );
    }
  }
  ExpandOption(prop){
    var orderIDExpanded_new=MenuOrders.ExpandOption({
      action:prop.action,
      orderID:prop.orderID,
      ExpandCategory:prop.ExpandCategory,
      orders:prop.orders,
      orderIDExpanded:this.state.orderIDExpanded
    });
    this.setState({
      orderIDExpanded: orderIDExpanded_new
    });
  } 

  render() {    
    return (
      <div className="div_customerorders"> 
        {this.renderOrderList({status:"Incomplete Orders"})}
        {this.renderOrderList({status:"Orders In Progress"})}
        {this.renderOrderList({status:"Completed"})}
      </div>
    );
  }
}