/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import jsFunc from '../../../func/jsFunc.js'; 
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";

const sections=[
	{
		header:"About Us",
		content:(<div>
			
			<p><img src="img/tom.png" height="70" alt="Robot-Hub"/></p>
			<p>If you’re new, I’m Tom. You can use me to pre-order coffee and other beverages so you can pop into the café, skip the queue to pick up and go.</p>
			<p>I’m currently in Wynyard Quarter, Auckland. More products, services, locations and merchants will soon be available.</p>
			<p>Sign up and collect store loyalty points that can be used to purchase your next order.</p>
			<p>This service does not cost you anything extra.</p>
			</div>)
	},
	{
		header:"Payment",
		content:(<div>
			<p>No hidden fees. Same price as in store.
We currently only offer Online EFTPOS as it is the most cost effective way to make this service free for you to use and for your favourite cafes to not have to pay huge fees. 
</p>
			<p>Online EFTPOS is available with ASB, Westpac & Co-Op bank app today. More to follow. Once you’ve selected your bank, open your bank’s app and click the notification to make the payment.
</p>
			</div>)
	},
	{
		header:"How it works",
		content:(<div>
			<p>Simple. Click to order online. Make the payment. Check the payment is received and order is being processed. Walk over and pickup when you’re there. Hassle free. Save time, skip the queue.
			</p>
			</div>)
	},
	{
		header:"Loyalty points",
		content:(<div>
			<p>We’ve struck up a deal with your favourites stores so when you order online through us, you’ll get loyalty points that can be used to offset your future orders. Typically, you will get 5% of the purchase value back in loyalty points. Sign up and start collecting points today.
			</p>
			</div>)
	},
	{
		header:"Stores",
		content:(<div>
			<p>We’ll be adding a lot more stores in the coming days so stay tuned. Let us know which stores you’ll like to see on here by sending us an email at hello@robot-hub.com
			</p>
			</div>)
	},
	{
		header:"Services",
		content:(<div>
			<p>Very soon we’ll be adding more products and services that you commonly use so you can speed up the things you have to get done. 
			</p>
			</div>)
	},
	{
		header:"Contact us",
		content:(<div>
			<p>If you have any questions or feedback, we’d love to hear from you. Send us an email hello@robot-hub.com
			</p>
			</div>)
	},
	{
		header:"Store or product feedback",
		content:(<div>
			<p>If you have feedback specific to the product or store you’ve bought from, please go directly back to them as they will be able to resolve it for you. We are not responsible for the end product or service that you receive as we are an online ordering platform. 
			</p>
			</div>)
	},
];

export default class Questions extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: true,
			PaymentNotice : "Loading..",
			expanded : []
    };
  }
  async componentDidMount() {
      
    /*
        this.getPaymentStatus()
        .then((data) => {
          //this.state.UserAddress=data;
          console.log(JSON.stringify(data));
          this.setState({
            Bank : data.Bank,
            PaymentStatus : data.PaymentStatus,
            PaymentAmount : data.PaymentAmount,
            creationTime : data.creationTime,
            orderIDhash : data.orderIDhash,
            PaymentNotice : data.PaymentNotice
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.setState({ isLoading: false });    
        */
  }

  getPaymentStatus = async event => {    
    var paymentId=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["p"]!==undefined){ paymentId=urlvar["p"];}    
    return await API.get('v1', '/Questions?p='+paymentId);
  }
	toggleShow(header){		
		if((this.state.expanded).indexOf(header) != -1){ 
			//expanded - remove from state
			var expanded=this.state.expanded;
			var expandedNew=[];
			for (var i=0; i<expanded.length;i++){
				if(expanded[i]!=header){expandedNew.push(header);}
			}
		}else{ //not expanded - add to state
			var expandedNew=this.state.expanded;
			expandedNew.push(header);
		}
		this.setState({expanded:expandedNew});
	}
  render() {
		var faqs=[];
		var expandInd,dcontent;

		for (var i=0; i<sections.length;i++){
			var section=sections[i];
			const header=section.header;			

			
			if((this.state.expanded).indexOf(header) != -1){ //expanded
				
				expandInd=" -";
				dcontent=(<div className="info_message">{section.content}</div>);
			}else{		
				expandInd=" +";
				dcontent="";
			}
			faqs.push(
				<div style={{
					'border-bottom':'1px solid #CCCCCC',
					'cursor':'pointer'
				}}>
					<div onClick={(() => this.toggleShow(header))} >
						<h3>{header} {expandInd} </h3>
					</div>
					{dcontent}
				</div>);		
		}
    return (
			<div>
			<div style={{"text-align":"center", "font-weight":"bold"}}>FAQS</div>
			
			{faqs}
      </div>
    );
  }
}