/* eslint {eqeqeq:0,no-redeclare:0,no-unused-vars:0} */
import React from "react";
import jsFunc from '../../../func/jsFunc.js'; 

const MenuOrders={

  test: function(){
    alert("something here coomadnpots");
  },

  total_orderprice: function(prop){
    var order=prop.order;
    var orderItems=order.orderItems;
    var totalprice=0;
    //Find Addons to the order if any
    for (var i=0;i<orderItems.length;i++){
      totalprice+=this.total_OrderItem_price({"OrderItem" : orderItems[i]});
    }
    return totalprice;
  },  

  total_OrderItem_price: function(prop){
    var OrderItem=prop.OrderItem;
    var addons=OrderItem.add_ons;
    var totalprice=0;
    if(OrderItem.Price){totalprice+=OrderItem.Price;}
    if(OrderItem.add_ons){
    for (var i=0;i<addons.length;i++){
      if(addons[i].addon_items){
      var addon_items=addons[i].addon_items;
      for (var ii=0;ii<addon_items.length;ii++){
        if(addon_items[ii].Price){totalprice+=addon_items[ii].Price;}
    }}}}
    return totalprice;
  },
  get_menuitem_summary: function(prop){
  var OrderItem=prop.OrderItem;
  var summary_arr=[];
  var add_on_qty; var add_on_name;
  var group_listing=[]; 
    if(OrderItem.add_ons){
      var groups=OrderItem.add_ons;
      for (var i=0;i<groups.length;i++){
        var group_name=groups[i].addon_group;
        group_listing=[]; 
        var add_ons=groups[i].addon_items;
        for (var ii=0;ii<add_ons.length;ii++){
          add_on_name=add_ons[ii].item_name;            
          add_on_qty=add_ons[ii].qty;

          //add addon to group
          group_listing.push({
            "name":add_on_name,
            "qty":add_on_qty
          })
        }
        //add group header
        summary_arr.push({
          "group_name": group_name,
          "group_listing": group_listing
        });

    }}
      
    var addon_summary=[];
    for (var i_sum=0;i_sum<summary_arr.length;i_sum++)
    {
      var listings="";
      var header_name=summary_arr[i_sum].group_name;
      group_listing=summary_arr[i_sum].group_listing;
      for (var i_list=0;i_list<group_listing.length;i_list++)
      {
        add_on_qty=group_listing[i_list].qty;
        add_on_name=group_listing[i_list].name;
        var dadd_on_name=add_on_name;
        if (add_on_qty>1){dadd_on_name+=" x"+add_on_qty;}
        var cm="";if (i_list>0){cm=", ";}
        dadd_on_name=cm+dadd_on_name;
        listings+=dadd_on_name;
      }
      addon_summary.push(
        <div>
          {header_name}: {listings}
        </div>
      );
    }
    return addon_summary;
  },
  
  renderOrderItem: function(prop){ //order frame - shows seleted objs
    var OrderItem=prop.OrderItem;
    var item_num=OrderItem.item_num;
    var dcurnum; dcurnum=" (#"+item_num+")"; //dcurnum=""; //item number
    var itemStatus=OrderItem.item_status.ConfirmType;
    
    //item_num_sel to put in state
    var item_num_sel=0;

    //always show summary
    //get total item price
    var totalprice=this.total_OrderItem_price({OrderItem:OrderItem});
    var dtotalprice="$"+jsFunc.damount(totalprice);

    var addon_summary=this.get_menuitem_summary({OrderItem:OrderItem});


    var div_class="td_tablet_hide";if(item_num_sel==item_num 
      || item_num_sel==null
      || item_num_sel==0
      ){div_class="";} div_class="  ";
        
    var class_sel="td_action_supplier"; 
   
    return (
    <div className={div_class}>
        <div className={class_sel}>
          <div>
            {OrderItem.item_name} 
            <span className='span-order'>{dcurnum}</span>
            <span className='span-price'>{dtotalprice}</span>
            
          </div>
          <span className='span-sub'>{addon_summary}</span>
            <div>
              Status:
              <span style={{"margin-left":"5px"}} className='span-sub-awaiting'>{itemStatus}</span></div>
        </div>
    </div>
    );
  },
  
  ExpandOption: function(prop){   
    var action=prop.action;
    var orderID=prop.orderID; 
    var ExpandCategory=prop.ExpandCategory;
    var orders=prop.orders;
    var orderIDExpanded=prop.orderIDExpanded;
    var orderIDExpanded_new=[];
    if(action=="add"){
      orderIDExpanded_new=orderIDExpanded;
      if(ExpandCategory!=undefined){
        for(var i=0;i<orders.length;i++){
          
          if(orders[i].order_stage==ExpandCategory){
            if(orderIDExpanded_new.indexOf(orders[i].OrderID)==-1){
            orderIDExpanded_new.push(orders[i].OrderID);}
          };
        }
      }else{
        orderIDExpanded_new.push(orderID);
      }
    }else if(action=="remove"){//remove
      for(var i=0;i<orderIDExpanded.length;i++){
        if(orderID!=orderIDExpanded[i]){
        orderIDExpanded_new.push(orderIDExpanded[i]);}
      }
    }
    return (orderIDExpanded_new);
  },
  renderOrder: function(prop){
    var order=prop.order;
    var orderIDExpanded=prop.orderIDExpanded;
    var func_expand=prop.func_expand;
    var orderButtons=prop.orderButtons;
    var orderID=order.OrderID;
    var Supplier=order.Supplier+" ";
    
    //var totalprice=this.total_orderprice({"order":order});
    var totalprice=this.total_orderprice({"order":order});
    
    var dtotal="";if (totalprice>0){dtotal=" $"+jsFunc.damount(totalprice)+"";}
    var dtime=""; dtime=order.TimeOrdered;
    var paymentStatus=order.payment_status.ConfirmType;
    var paymentStage=order.payment_status.stage_name;
    var paymentStatus_class="span-sub-complete";
    if(paymentStage=="Pending"){
      paymentStatus_class="span-sub-awaiting";
    }
    
    //buttons
    var buttons=[];
    for (var i=0;i<orderButtons.length;i++){
      var className=orderButtons[i].className+" myButton ";
      buttons.push(
        <button 
          className={className} 
          onClick={orderButtons[i].func}>
          {orderButtons[i].name}
        </button>
      );
    }

    var OrderItems=[]; var status;
    if(orderIDExpanded.indexOf(orderID) != -1){ //only show breakdown if expanded 
    for (var i=0;i<order.orderItems.length;i++){
      var OrderItem=order.orderItems[i];
      OrderItems.push(this.renderOrderItem({OrderItem:OrderItem}));
      status="";
    }}

    var onclick_func; var dexpand=" +";
    if(orderIDExpanded.indexOf(orderID) != -1){ //if expanded, remove from array. 
      dexpand=" -";
      onclick_func=func_expand.remove;
    }else{
      onclick_func=func_expand.add;
    }
    var dpaymentStatus=(<div><span className={paymentStatus_class}>{paymentStatus}</span></div>);
    
    return (
      <div className="td_MenuOrder_wrap">
        <div className="td_header_MenuOrder">
          
          <span className="span-button" onClick={onclick_func}>{Supplier}
            <span className='span-sub-black'>
            (#{order.OrderNumber})</span> 
            <span className="span-sub">
            {dexpand}</span>
          </span>


          {status}

          <div className="td_floatright">{buttons}</div>
          <div className="td_floatright">{dtotal}{dpaymentStatus}</div>
          <div><span className='span-sub-black'>{dtime}</span>    </div>
        </div>


          
        
        
        {OrderItems}
      </div>
    );
  },
  
  renderMenuCategory: function(prop){
    var categoryName=prop.name;
    var expandedOptions=prop.expandedOptions || [];
    var toggleFunc=prop.toggleFunc;


    var categoryId=prop.categoryId;
    var img="";

    var img_map=[
      {categoryId:1,img_src:"img/menucat/coffee.png"},
      {categoryId:2,img_src:"img/menucat/pie.png"},
      {categoryId:11,img_src:"img/menucat/tea.png"},
      {categoryId:12,img_src:"img/menucat/hotdrink.png"},
      {categoryId:13,img_src:"img/menucat/smoothie.png"},
      {categoryId:14,img_src:"img/menucat/iceddrink.png"},
      {categoryId:15,img_src:"img/menucat/iceddrink.png"},
      {categoryId:16,img_src:"img/menucat/iceddrink.png"},
      {categoryId:17,img_src:"img/menucat/milkshake.png"},
      {categoryId:18,img_src:"img/menucat/wallet.png"},
      {categoryId:19,img_src:"img/menucat/wallet.png"},
      //{categoryId:2,img_src:"img/select.png"},
    ];
    var img_src;
    img_map.forEach(map=>{if (map.categoryId==categoryId){
      img_src=map.img_src}})
    var img=img_src?(<div style={{
      "float":"left",
      "text-align":"center",
      "width":"50px"
      }}>
      <img src={img_src} height="30" 
      style={{
        "marginLeft":"0px",
        "marginRight":"15px",
      }} alt="Robot-Hub"/>
      </div>
    ):"";
    
    var showExpand=" +";
    if(expandedOptions.indexOf(categoryName) != -1){
      showExpand=" -";
    }

    return(
      <div className='td_header_MenuCategory' style={{"verticalAlign":"middle"}} 
      onClick={toggleFunc}>
        {img}
        <div style={{"float":"left", "padding":"6px 0px"}}>
          <span>
            {categoryName}
            {showExpand}
          </span>
        </div>
      </div>);
  }



}

export default MenuOrders;
/*

export function test(){
    alert("something here coomadnpots");
}

export function total_orderprice(prop){
    var order=prop.order;
    var orderItems=order.orderItems;
    var totalprice=0;
    //Find Addons to the order if any
    for (var i=0;i<orderItems.length;i++){
      totalprice+=this.total_OrderItem_price({"OrderItem" : orderItems[i]});
    }
    return totalprice;
  }
  
export function total_OrderItem_price(prop){
    var OrderItem=prop.OrderItem;
    var addons=OrderItem.add_ons;
    var totalprice=0;
    if(OrderItem.Price){totalprice+=OrderItem.Price;}
    if(OrderItem.add_ons){
    for (var i=0;i<addons.length;i++){
      if(addons[i].addon_items){
      var addon_items=addons[i].addon_items;
      for (var ii=0;ii<addon_items.length;ii++){
        if(addon_items[ii].Price){totalprice+=addon_items[ii].Price;}
    }}}}
    return totalprice;
  }
export function get_menuitem_summary(prop){
  var OrderItem=prop.OrderItem;
  var summary_arr=[];
  var add_on_qty; var add_on_name;
  var group_listing=[]; 
    if(OrderItem.add_ons){
      var groups=OrderItem.add_ons;
      for (var i=0;i<groups.length;i++){
        var group_name=groups[i].addon_group;
        group_listing=[]; 
        var add_ons=groups[i].addon_items;
        for (var ii=0;ii<add_ons.length;ii++){
          add_on_name=add_ons[ii].item_name;            
          add_on_qty=add_ons[ii].qty;

          //add addon to group
          group_listing.push({
            "name":add_on_name,
            "qty":add_on_qty
          })
        }
        //add group header
        summary_arr.push({
          "group_name": group_name,
          "group_listing": group_listing
        });

    }}
      
  var addon_summary=[];
  for (var i_sum=0;i_sum<summary_arr.length;i_sum++)
  {
    var listings="";
    var header_name=summary_arr[i_sum].group_name;
    group_listing=summary_arr[i_sum].group_listing;
    for (var i_list=0;i_list<group_listing.length;i_list++)
    {
      add_on_qty=group_listing[i_list].qty;
      add_on_name=group_listing[i_list].name;
      var dadd_on_name=add_on_name;
      if (add_on_qty>1){dadd_on_name+=" x"+add_on_qty;}
      var cm="";if (i_list>0){cm=", ";}
      dadd_on_name=cm+dadd_on_name;
      listings+=dadd_on_name;
    }
    addon_summary.push(
      <div>
        {header_name}: {listings}
      </div>
    );
  }
  return addon_summary;

}
*/