/* eslint-disable */
import ReactDOM from "react-dom";
import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem, FormGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import jsFunc from '../../../func/jsFunc.js'; 
//import { Link } from "react-router-dom";
//import { ListGroup, ListGroupItem } from "react-bootstrap";



export default class ConfirmPayment extends Component {

  constructor(props) {
    super(props);
    
    var paymentId=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["p"]!==undefined){ paymentId=urlvar["p"];}

    this.state = {
      isLoading: true,
      paymentId: paymentId,
      loadingStates : {
        resubmit:"Resend Payment"
      },
      Bank : "Loading..",
      PaymentStatus : "Loading..",
      PaymentAmount : 0,
      creationTime : "Loading..",
      orderIDhash : "Loading..",
      nextPage : "",
      PaymentNotice : "Loading..",
      payMethod : "Loading..",
      time: Date.now()
    };
  }
  async componentDidMount() {
    //refresh 3 secs

    try {
      setInterval(async () => {

        this.getPaymentStatus()
        .then((data) => {
          //this.state.UserAddress=data;
          console.log(JSON.stringify(data));
          this.setState({
            Bank : data.Bank,
            PaymentStatus : data.PaymentStatus,
            PaymentAmount : data.PaymentAmount,
            creationTime : data.creationTime,
            orderIDhash : data.orderIDhash,
            nextPage : data.nextPage,
            PaymentNotice : data.PaymentNotice,
            payMethod : data.payMethod
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.setState({ isLoading: false });    

      }, 3000);
    } catch(e) {
      console.log(e);
    }

  }
  async resubmitPayment_exec() {
    this.setState({loadingStates:{resubmit:"Resending.. Please wait.."}});        
    var paymentId=this.state.paymentId;
    return await API.post('v1', '/customerorders',
    {
      'Content-Type': 'application/json',
      body: {
        action: "resubmitpayment",
        paymentId: paymentId,
      }
    })
    .then(result => {
      paymentId=result.paymentId;
      window.location.href='/confirmpayment?p='+paymentId;
    });    
  }
  getPaymentStatus = async event => {    
    var paymentId=0;
    var urlvar=jsFunc.getUrlParameter(window.location.href);
    if (urlvar["p"]!==undefined){ paymentId=urlvar["p"];}    
    return await API.get('v1', '/confirmpayment?p='+paymentId);
  }
    
  render() {
    var buttonsModel=[];
    if (this.state.PaymentStatus=="Payment Received"){      
      var orderIDhash=this.state.orderIDhash;
      var nextPage=this.state.nextPage;
      buttonsModel.push({
        name:"Track Order",
        className:"myButton_blue",
        href:nextPage
        //href:"/orders?o="+orderIDhash,
      });
    }else {
    //if (this.state.PaymentStatus=="SUBMITTED")
      /*
      buttonsModel.push({
        name:"Refresh",
        className:"myButton_blue",
        //onclick:(() => this.forceUpdate()),
        onclick:(() => window.location.reload()),
      }); */
      buttonsModel.push({
        name:this.state.loadingStates.resubmit,
        className:"myButton_green",
        onclick:(() => this.resubmitPayment_exec()),
      });
    }
    
    var buttons=jsFunc.display_buttons(buttonsModel);
    var damount=jsFunc.damount(this.state.PaymentAmount);
    var creationTime=this.state.creationTime;
    
    return (
      <div>
        <div style={{
          "margin-bottom":"20px"
        }}>{buttons}</div> 
        <p><b>Order:</b> {creationTime}</p>
        <p><b>Payment Method:</b> {this.state.payMethod}</p>
        <p><b>Bank:</b> {this.state.Bank}</p>
        <p><b>Status:</b> {this.state.PaymentStatus}</p>
        <p><b>Amount:</b> ${damount}</p>
        <p>{this.state.PaymentNotice}</p>
        
      </div>
    );
  }
}